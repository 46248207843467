import { UserAgentApplication } from 'msal';
import config from './shared/configService';

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: ["openid"]
    },
    TOKEN_REQUEST: {
        scopes: [config.clientId]
    }
}

export const msalApp = new UserAgentApplication({
    auth: {
        clientId: config.clientId,
        authority: config.authority,
        redirectUri: config.redirectUri,
        validateAuthority: false
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
})