import { Typography } from '@material-ui/core';
import React, { Component, useEffect } from 'react';
import { Graph } from 'tryal-ui';
import * as answerService from '../../../shared/answerService';
import uuid from "uuid";

class TryalGraph extends React.Component {

    componentDidMount() {
        const element = document.getElementById(this.props.graphId);
        const comp = new Graph({
            target: element,
        });
    }

    render() {
        return (
                <div id={this.props.graphId} style={{fontSize: "1.5rem"}}></div>
        )
    }

}

export default TryalGraph;