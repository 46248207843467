import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    media: {
        height: 'auto',
        margin: 'auto',
        display: 'block',
        width: '50%',
        minWidth: '300px',
        maxWidth: '800px',
        padding: "5px"
      },
    
  });

export default function Image({url, binaryString}){

    const classes = useStyles();

    if(url != null && url != ""){
        return (<img src={url} className={classes.media}></img>)
    }else if(binaryString != null && binaryString != ""){
        return (<img src={`data:image/png;base64,${binaryString}`} className={classes.media} />)
    }else{
        return (<React.Fragment></React.Fragment>)
    }
}