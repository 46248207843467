import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardContent from '@material-ui/core/CardContent';
import * as answerService from '../../../shared/answerService';
import { Checkbox } from '@material-ui/core';

export default function MultipleAnswers(props){

    return(
        <Grid item>
              <CardContent>
                  {
                      props.isReview ? <CheckboxesReview currentQuestion={props.currentQuestion}/> : <Checkboxes currentQuestion={props.currentQuestion} />
                  }
              </CardContent>
        </Grid>
    )
}

function Checkboxes(props){

    const handleChange = (event) => {
        answerService.setCheckedAnswerValue(props.currentQuestion.id, event.target.name, event.target.checked)
      };

    return(
        props.currentQuestion.options.map((option) => 
        <FormControlLabel alignItems="center" control={<Checkbox name={option} onChange={handleChange}/>} label={option} />
    ))
}

function CheckboxesReview(props){

    return(
        props.currentQuestion.options.map((option) => 
        <FormControlLabel control={<Checkbox name={option} checked={props.currentQuestion.userAnswer.includes(option)}/>} label={option} />
    ))
}