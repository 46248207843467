import * as apiService from '../shared/apiService';

var userAnswer = {};

export function setAnswerValue(questionId, answerValue){
    userAnswer[questionId] = answerValue;
}

export function getAnswerValue(questionId){
    return userAnswer[questionId];
}

export function resetAnswerValue(){
    userAnswer = {};
}

export function setCheckedAnswerValue(questionId, answerValue, checked){
    if(userAnswer[questionId] == null)
        userAnswer[questionId] = [];

    if(checked){
        userAnswer[questionId].push(answerValue);
    }else{
        const index = userAnswer[questionId].indexOf(answerValue);

        if (index > -1) {
            userAnswer[questionId].splice(index, 1);
        }
    }
}

export async function processAnsweredQuestion(submission) {
    var marksAwarded = 0;

    await awardAnsweredQuestionMarks(submission).then((result) => {
        marksAwarded = result
    })

    return marksAwarded;
}


async function awardAnsweredQuestionMarks(submission) {

    return new Promise(async function (resolve, reject) {
        await apiService.awardMarksAnsweredQuestion({ submission }).then((function (response) {
            resolve(response.marksAwarded);
        })).catch(function (response) {
            reject(false);
        })
    })

}