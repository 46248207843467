import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import * as apiService from '../shared/apiService';
import * as answerService from '../shared/answerService';
import * as commonService from '../shared/commonService';
import { ToggleButton } from "react-bootstrap";

var localStorage = window.localStorage;

var initialState = {
    questions: [],
    currentQuestion: { id: uuidv4(), question: "", options: [], layoutType: -1, relativeImagePath: "", startOfQuestion: moment(), difficulty: -1 },
    answeredQuestions: [],
    submittedGroupedQuestions: [],
    startOfExam: moment(),
    currentQuestionIndex: 0,
    redirectToReview: false,
    isReview: false,
    currentQuestionIndex: 0,
    loading: true,
    submitDisabled: false
}

var examPreferences = {};

const fetchExamQuestionsType = "FETCH_EXAM_QUESTIONS_TYPE";
const updateCurrentQuestionType = "UPDATE_CURRENT_QUESTION_TYPE";
const updateCurrentQuestionIndex = "UPDATE_CURRENT_QUESTION_INDEX_TYPE";
const submitAnswerType = "SUBMIT_ANSWER_TYPE";
const submitGroupedQuestionType = "SUBMIT_GROUPED_QUESTION_TYPE";
const fireRedirect = "FIRE_REDIRECT_TYPE";
const toggleLoadingType = "TOGGLE_LOADING_TYPE";
const toggleSubmitType = "TOGGLE_SUBMIT_TYPE";
const updateIsReview = "UPDATE_IS_REVIEW";
const clear = "CLEAR";

const localBaseUrl = "http://localhost:7071/";
const baseUrl = localBaseUrl;
const fetchExamQuestionsUrl = baseUrl + "api/FetchExamQuestions";
const completeExamUrl = baseUrl + "api/CompleteExam";

export const actionCreators = {

    fetchExamQuestions: (preferences, isPractice) => async (dispatch, getState) => {

        dispatch({ type: toggleLoadingType, loading: true });

        var userId = localStorage.getItem("userId");
        var payload = preferences;
        examPreferences = preferences;
        payload["isPractice"] = isPractice;
        payload["userId"] = userId;

        apiService.fetchExamQuestions(payload).then(async function (response) {
            var questions = response
            await commonService.sleep(1200);

            if (questions.length > 0) {
                var firstQuestion = questions[0];
                firstQuestion.startOfQuestion = moment();
                dispatch({ type: updateCurrentQuestionType, currentQuestion: firstQuestion })
            }

            dispatch({ type: toggleLoadingType, loading: false });
            dispatch({ type: fetchExamQuestionsType, questions });
        }).catch(err => {

        })
    },

    submitGroupedAnswer: (examId, isPractice) => async (dispatch, getState) => {

        dispatch({type: toggleSubmitType, disabled: true})
        var startOfQuestion = Object.assign({}, getState().exam.currentQuestion.startOfQuestion);
        var timeTakenSeconds = moment.duration(moment().diff(startOfQuestion)).asSeconds();

        var currentQuestionGroup = Object.assign({}, getState().exam.currentQuestion);
        var questions = currentQuestionGroup.questions;

        var userId = localStorage.getItem("userId");
        var submittedAnswers = [];
        var submission = {
            templateSeed: currentQuestionGroup.templateSeed, 
            externalId: currentQuestionGroup.externalId, 
            marksAvailable: currentQuestionGroup.marks,
            deckId: currentQuestionGroup.deck};

        for (var currentQuestion of questions) {

            var questionId = currentQuestion.id;
            var answerValue = answerService.getAnswerValue(questionId);
            answerValue = answerValue == undefined ? "" : answerValue;
            currentQuestion.userAnswer = answerValue;

            var submittedAnswer = {
                id: uuidv4(),
                questionId: currentQuestion.id,
                answer: currentQuestion.answer,
                userAnswer: answerValue,
                multipleAnswers: currentQuestion.multipleAnswers,
                part: currentQuestion.part
            };

            submittedAnswers.push(submittedAnswer);
        }

        submission["submittedAnswers"] = submittedAnswers;

        var marksAwarded = await answerService.processAnsweredQuestion(submission); 
        currentQuestionGroup.marksAwarded = marksAwarded;

        var answeredQuestion = {
            id: uuidv4(), 
            examId: examId, 
            userId: userId, 
            deckId: currentQuestionGroup.deck,
            difficulty: currentQuestionGroup.difficulty,
            subTopicId: currentQuestionGroup.subTopic,
            timeTakenSeconds: timeTakenSeconds, 
            questionId: currentQuestionGroup.id, 
            answeredAt: moment(),
            question: currentQuestionGroup,
            marksAwarded: marksAwarded,
            marksAvailable: currentQuestionGroup.marks
        };

        dispatch({type: submitAnswerType, answeredQuestion: answeredQuestion});

        if (isPractice) {

            dispatch({ type: submitGroupedQuestionType, currentQuestionGroup });
            dispatch({ type: updateCurrentQuestionType, currentQuestion: currentQuestionGroup });
            dispatch({ type: updateIsReview, isReview: true });

        } else {
            nextQuestion(dispatch, getState, isPractice);
        }
    },

    nextQuestion: (isPractice) => async (dispatch, getState) => {
        nextQuestion(dispatch, getState, isPractice);
    },

    clear: () => async (dispatch, getState) => {
        answerService.resetAnswerValue();
        dispatch({ type: clear });
    }
}

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type == fetchExamQuestionsType) {
        return {
            ...state,
            questions: action.questions
        }
    }

    if (action.type == submitAnswerType) {
        return {
            ...state,
            answeredQuestions: [...state.answeredQuestions, action.answeredQuestion]
        }
    }

    if (action.type == submitGroupedQuestionType) {
        return {
            ...state,
            submittedGroupedQuestions: [...state.submittedGroupedQuestions, action.currentQuestionGroup]
        }
    }

    if (action.type == updateCurrentQuestionType) {
        return {
            ...state,
            currentQuestion: action.currentQuestion
        }
    }

    if (action.type == updateCurrentQuestionIndex) {
        return {
            ...state,
            currentQuestionIndex: action.currentQuestionIndex
        }
    }

    if (action.type == fireRedirect) {
        return {
            ...state,
            redirectToReview: true
        }
    }

    if (action.type == toggleLoadingType) {
        return {
            ...state,
            loading: action.loading
        }
    }

    if (action.type == updateIsReview) {
        return {
            ...state,
            isReview: action.isReview
        }
    }

    if(action.type == toggleSubmitType){
        return {
            ...state, 
            submitDisabled: action.disabled
        }
    }

    if (action.type == clear) {

        return {...initialState}
    }

    return state;
}

function sortQuestionsByNumber(a, b) {
    return a.questionNumber > b.questionNumber;
}

function nextQuestion(dispatch, getState, isPractice) {
    var questions = Array.from(getState().exam.questions);
    var currentQuestionIndex = getState().exam.currentQuestionIndex;
    currentQuestionIndex++

    dispatch({ type: updateIsReview, isReview: false });

    if (currentQuestionIndex >= questions.length) {

        dispatch({ type: toggleLoadingType, loading: true });
        var answeredQuestions = Array.from(getState().exam.answeredQuestions);
        var submittedGroupQuestions = Array.isArray(getState().exam.submittedGroupedQuestions) ? Array.from(getState().exam.submittedGroupedQuestions) : [];
        apiService.completeExam({ answeredQuestions, submittedGroupQuestions, isPractice, examPreferences }).then(function (response) {
            dispatch({ type: toggleLoadingType, loading: false });
            dispatch({ type: fireRedirect });
        }).catch(function (response) {
            //redirect to error page
            console.log(response);
        });
    } else {

        var newQuestion = questions[currentQuestionIndex]
        newQuestion.startOfQuestion = moment();

        dispatch({ type: updateCurrentQuestionType, currentQuestion: newQuestion });
        dispatch({ type: updateCurrentQuestionIndex, currentQuestionIndex: currentQuestionIndex })
        dispatch({type: toggleSubmitType, disabled: false});
    }
}

function arraysEqual(_arr1, _arr2) {

    if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length)
        return false;

    var arr1 = _arr1.concat().sort();
    var arr2 = _arr2.concat().sort();

    for (var i = 0; i < arr1.length; i++) {

        if (arr1[i] !== arr2[i])
            return false;

    }

    return true;
}