import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/BenchmarkExam';
import { Link, BrowserRouter as Router } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import QuestionAnswerForm from './shared/QuestionAnswerForm';
import Header from './shared/Header';
import Footer from './shared/Footer';
import '../layout.css';
import * as commonService from '../shared/commonService';
import ClipLoader from "react-spinners/ClipLoader";
import RingLoader from "react-spinners/RingLoader";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import ProgressBar from './shared/Misc/ProgressBar';
import styled from 'styled-components';
import FormLabel from '@material-ui/core/FormLabel';
import { LinkContainer } from 'react-router-bootstrap';
import Loading from './shared/Loading';

class BenchmarkExam extends React.Component {

    componentDidUpdate(prevProps) {
        if (this.props.redirectToReview == true) {
            this.props.history.push({
                pathname: "/BenchmarkExamSubmission",
                examId: this.props.location.examId
            })
        }
    }

    componentWillUnmount() {
        this.props.clear();
    }

    componentWillMount() {

        if (this.props.location.isPractice == null) {
            this.props.history.push({
                pathname: "/"
            })
        }else{
            mixpanel.track("BenchmarkExam");
        }

        this.props.fetchExamQuestions(this.props.location.preferences, this.props.location.isPractice);
    }

    render() {

        if(this.props.loading == false){
            return (

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}>
                    <Header>
                    </Header>
                    <Grid container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={{ marginTop: "20vh", marginBottom: "2vh" }}
                    >
                        <QuestionAnswerFormContainer {...this.props} />
                    </Grid>
                    <Footer></Footer>
                </div>
            )
        }else{
            return <Loading />
        }
    }
}

function QuestionAnswerFormContainer(props) {
    return (
        <React.Fragment>
            <Grid item>
                <Typography variant="h3" style={{color: "grey"}}>{props.subTopicLabel}</Typography>
            </Grid>
            <Divider/>
            <Grid item>
                <BackLink/>
                <Grid item>
                    {/* <LinearProgress variant="determinate" color="secondary" value={(((props.currentSubTopicIndex) / (props.totalSubTopics)) * 100)} style={{ width: "90vw", maxWidth: "800px" }} /> */}
                    <ProgressBar value={(((props.currentSubTopicIndex) / (props.totalSubTopics)) * 100)} />
                </Grid>
                <QuestionAnswerForm {...props}></QuestionAnswerForm> 
            </Grid>
        </React.Fragment>

    )
}

function BackLink(){

    const HoverText = styled.p`
    font-size:14px;
    :hover {
      font-weight: bold;
      cursor: pointer;
    }
  `

    return(
        <Grid item>
             <LinkContainer to={{
                        pathname: '/'
                    }}><FormLabel size="large"><HoverText>⇦ Exit</HoverText></FormLabel></LinkContainer>
        </Grid>
    )
}



export default connect(
    state => state.benchmarkExam,
    dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(BenchmarkExam);