import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import uuid from "uuid";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  typographyContent: {
    width: "90vw",
    maxWidth: "800px"
  }
}));

function PreExam(props) {

  const classes = useStyles();

  if (props.location.examId == null) {
    props.history.push({
      pathname: "/"
    })
  }else{
    mixpanel.track("PreExam");
  }

  if (props.location.examId != null) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>
        <Header></Header>
        <Grid container
          direction="column"
          justify="flex-start"
          alignItems="center"

          style={{ marginTop: "12vh", marginBottom: "2vh" }}
        >
          <Grid item>
            <img style={{ width: "80vw", height: "auto", maxWidth: "600px", marginBottom: "5vh" }} src="https://nebulaexamsenterprise.blob.core.windows.net/media/graphics/rocket-new-bg.svg?sv=2019-12-12&st=2021-01-26T21%3A02%3A53Z&se=2023-01-27T21%3A02%3A00Z&sr=b&sp=r&sig=9g1ZWKet2W1t9u1Bl%2FdtheTS79tUoTxHz27yTWQcM00%3D"></img>

          </Grid>
          <Grid item>
            <Typography variant="h3" align="center" className={classes.typographyContent}><b>Prepare to start the exam</b></Typography>
            <br />
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center" className={classes.typographyContent}>There are <b>{JSON.parse(props.deckInfo.maxMarksParams).exam} marks</b> available for this exam</Typography>
            <br />
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center" className={classes.typographyContent}>The time spent on the exam will be recorded and available to review after completion, however the assessment is <b>not time limited.</b></Typography>
            <br />
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center" className={classes.typographyContent}><b>Submitted answers are final.</b> You will not be able to change an answer to a question once you have clicked submit.</Typography>
            <br />
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center" className={classes.typographyContent}>If you exit from the exam page before you have completed the exam then your answers will not be recorded.</Typography>
            <br />
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center" className={classes.typographyContent}>Click on the button below to start the exam. <b>Good luck!</b></Typography>
          </Grid>
          <Grid item>
            <Link
              to={{
                pathname: '/Exam',
                preferences: props.location.preferences,
                examId: props.location.examId,
                isPractice: false
              }}

              style={{ textDecoration: "none" }}
            ><Button style={{ marginTop: "5vh", marginRight: "2.5vw" }} color="secondary" variant="contained">Start Exam</Button></Link>
            <Link
              to={{
                pathname: '/',
              }}

              style={{ textDecoration: "none" }}
            ><Button style={{
              marginTop: "5vh", marginLeft: "2.5vw", borderWidth: "1.5px",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4.06612px 16.2645px", backgroundColor: "white"
            }} color="secondary" variant="outlined">Back To Home</Button></Link>
          </Grid>
        </Grid>
        <Footer></Footer>
      </div>
    )
  } else {
    return (<React.Fragment />)
  }
}

export default connect(
  state => state.deck,
)(PreExam);
