import * as commonService from '../shared/commonService';
import * as apiService from '../shared/apiService';

var localStorage = window.localStorage;

const updateCurrentReviewQuestionType = "UPDATE_CURRENT_REVIEW_QUESTION_TYPE";
const fetchReviewQuestionsType = "FETCH_REVIEW_QUESTIONS_TYPE";
const updateCurrentReviewQuestionIndex = "UPDATE_CURRENT_REVIEW_QUESTION_INDEX_TYPE";
const loading = "LOADING_TYPE";

var initialState = {
    questions: [],
    currentQuestion: {},
    currentQuestionIndex: 0,
    isReview: true
};

export const actionCreators = {
    fetchReview: (examId) => async(dispatch, getState) => {

        dispatch({type: loading, loading: true});
        var userId = localStorage.getItem("userId");
        var data = {userId, examId};

        apiService.fetchReviewQuestions(data).then(function(response) {

            dispatch({type: loading, loading: false});
            var questions = response;

            if(questions.length > 0){
                var firstQuestion = questions[0];
                dispatch({type: updateCurrentReviewQuestionType, currentQuestion: firstQuestion})
            }
    
            dispatch({ type: fetchReviewQuestionsType, questions });
        });
    },

    nextReviewQuestion: () => async(dispatch, getState) => {
        var questions = Array.from(getState().examReview.questions);
        var currentQuestionIndex = getState().examReview.currentQuestionIndex;
        currentQuestionIndex++;

        var newQuestion = questions[currentQuestionIndex];

        dispatch({type: updateCurrentReviewQuestionType, currentQuestion: newQuestion});
        dispatch({type: updateCurrentReviewQuestionIndex, currentQuestionIndex : currentQuestionIndex})
    },

    previousReviewQuestion: () => async(dispatch, getState) => {
        var questions = Array.from(getState().examReview.questions);
        var currentQuestionIndex = getState().examReview.currentQuestionIndex;
        currentQuestionIndex--;

        var newQuestion = questions[currentQuestionIndex];

        dispatch({type: updateCurrentReviewQuestionType, currentQuestion: newQuestion});
        dispatch({type: updateCurrentReviewQuestionIndex, currentQuestionIndex : currentQuestionIndex})
    }
}

export const reducer = (state, action) => {
    state = state || initialState;

    if(action.type == fetchReviewQuestionsType){
        return {
            ...state,
            questions: action.questions,
            currentQuestionIndex: 0
        }
    }

    if(action.type == updateCurrentReviewQuestionType){
        return{
            ...state, 
            currentQuestion: action.currentQuestion
        }
    }

    if(action.type == updateCurrentReviewQuestionIndex){
        return {
            ...state, 
            currentQuestionIndex: action.currentQuestionIndex
        }
    }

    if(action.type == loading){
        return {
            ...state,
            loading: action.loading
        }
    }

    return state;
};