import React, { Component, useEffect } from 'react';
import { Typography } from '@material-ui/core';

export default function Latex(props) {

    let node = React.createRef();
    useEffect(() => {
        renderMath();
    });

    const renderMath = () => {
        window.MathJax.Hub.Queue([
            "Typeset",
            window.MathJax.Hub,
            node.current
        ]);

        window.MathJax.Hub.processSectionDelay = 0;
    }

    return (
        <div ref={node}>
            <Typography variant="body1" align="center">
                {props.children}
            </Typography>
        </div>
    );
}