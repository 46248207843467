import * as apiService from '../shared/apiService';
const localStorage = window.localStorage;

const fetchedUserDeck = "FETCHED_USER_DECK_TYPE";
const fetchedDecks = "FETCHED_DECKS";
const fetchDeckInfo = "FETCH_DECK_INFO";
const updateCurrentTopicId = "UPDATE_CURRENT_TOPIC_ID";
const noDecksReturned = "NO_DECKS_RETURNED";
const loading = "LOADING";
const submittedReviewSuccessfulType = "SUBMITTED_REVIEW_SUCCESSFUL_TYPE";
const benchmarkPopupUpdate = "BENCHMARK_POPUP_UPDATE";
const clear = "CLEAR";

var initialState = {
    subjects: [],
    currentTopicId: "",
    courseId: null,
    subscribedDecks: [],
    availableDecks: [],
    loading: true,
    submittedReviewSuccessfully: false,
    benchmarkPopupShow: false,
    deckInfo: {maxMarksParams: {}, featureToggles: '{"benchmarkExam": false}'}
};

export const actionCreators = {
    fetchUserDeck: (userId, name, email) => async(dispatch, getState) => {

        dispatch({type: loading, loading: true});

        apiService.fetchUserDeck({"userId": userId,"deckId": localStorage.getItem("lastSelectedDeckId"), "name": name, "email": email}).then(function(response){
            dispatch({type: loading, loading: false});

            if(response == "nodecks"){
                    apiService.fetchDecks({"userId": userId}).then(function(response){
                        dispatch({type: fetchedDecks, subscribedDecks: response.subscribedDecks, availableDecks: response.availableDecks});
                    });

                dispatch({type: noDecksReturned, deck: response});
            }else{
                dispatch({type: fetchedUserDeck, deck: response});

                apiService.fetchDeckInfo({deckId: response.id}).then(function(response){
                    dispatch({type: fetchDeckInfo, deckInfo: response})
                })
            }
        });
    },

    viewSubTopic: (topicId) => async(dispatch, getState) => {
        dispatch({type: updateCurrentTopicId, topicId: topicId})
    },

    fetchDecks: (userId) => async(dispatch, getState) => {
        apiService.fetchDecks({"userId": userId}).then(function(response){
            dispatch({type: fetchedDecks, subscribedDecks: response.subscribedDecks, availableDecks: response.availableDecks});
        });
    },

    addDeckToUserDeck: (userId, deckId) => async(dispatch, getState) => {

        dispatch({type: loading, loading: true});

        apiService.addDeckToUserDeck({"userId": userId, "deckId": deckId}).then(function(response){
            localStorage.setItem("lastSelectedDeckId", deckId);
            dispatch({type: fetchedUserDeck, deck: response});
            dispatch({type: loading, loading: false});

            apiService.fetchDecks({"userId": userId}).then(function(response){
                dispatch({type: fetchedDecks, subscribedDecks: response.subscribedDecks, availableDecks: response.availableDecks});
                dispatch({type: benchmarkPopupUpdate, benchmarkPopupShow: true});
            });
        })
    },

    benchmarkPopupClose: () => async(dispatch, getState) => {
        dispatch({type: benchmarkPopupUpdate, benchmarkPopupShow: false});
    },

    submitReview: (userId, userName, deckId, rating, review) => async(dispatch, getState) => {

        apiService.submitReview({userId, userName, deckId, rating, review}).then(function(response){
            dispatch({type: submittedReviewSuccessfulType});
        }).catch(function(response){
            console.log("Review wasn't submitted successfully");
        })

    },

    switchDeck: (newDeckId) => async(dispatch, getState) => {

        dispatch({type: loading, loading: true});
        localStorage.setItem("lastSelectedDeckId", newDeckId);

        apiService.fetchUserDeck({"userId": localStorage.getItem("userId"), "deckId": newDeckId}).then(function(response){
            dispatch({type: fetchedUserDeck, deck: response});
            dispatch({type: updateCurrentTopicId, topicId: ""})
            dispatch({type: loading, loading: false});
        });
    },

    clear: () => async(dispatch, getState) => {
        dispatch({type: clear});
    }
}

export const reducer = (state, action) => {
    state = state || initialState;

    if(action.type == fetchedUserDeck){
        return{
            ...state, 
            subjects: action.deck.subjects,
            courseId: action.deck.id
        }
    }

    if(action.type == noDecksReturned){
        return{
            ...state, 
            course: null
        }
    }

    if(action.type == updateCurrentTopicId){
        return{
            ...state, 
            currentTopicId: action.topicId
        }
    }

    if(action.type == fetchedDecks){
        return{
            ...state, 
            subscribedDecks: action.subscribedDecks,
            availableDecks: action.availableDecks
        }
    }

    if(action.type == benchmarkPopupUpdate){
        return{
            ...state, 
            benchmarkPopupShow: action.benchmarkPopupShow
        }
    }

    if(action.type == loading){
        return{
            ...state, 
            loading: action.loading
        }
    }

    if(action.type == fetchDeckInfo){
        return {
            ...state, 
            ...action.deckInfo
        }
    }

    if(action.type == clear){
        return{
            ...initialState 
        }
    }

    if(action.type == submittedReviewSuccessfulType){
        return{
            ...state,
            submittedReviewSuccessfully: true
        }
    }

    return state;
};

