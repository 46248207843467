import React, { Component } from 'react';
import { msalApp, GRAPH_REQUESTS } from './authUtils';

export default AuthComp =>
    class AuthProvider extends Component {
        constructor(props) {
            super(props);
            this.state = {
                account: null, error: null,
            };
        }

        async onSignIn() {
            return msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN).then(response => {

                var account = msalApp.getAccount()
                if (account) {
                    mixpanel.identify(account.accountIdentifier);
                    mixpanel.people.set({'$email': account.idToken.emails[0] })
                    this.setState({
                        account,
                    })
                }
            })
            .catch(err => {
                console.log(err);
            })
        }

        onSignOut() {
            msalApp.logout()
        }

        async componentDidMount() {
            msalApp.handleRedirectCallback((error, response) => {
                if (error) {
                    const errorMessage = error.errorMessage ? error.errorMessage : 'Unable to acquire access token.' // setState works as long as navigateToLoginRequestUrl: false 

                    this.setState({
                        error: errorMessage,
                    })
                }

            })

            var account = msalApp.getAccount()
            if (account) {
                this.setState({
                    account,
                })
            } else {
                this.onSignIn()
            }
        }

        render() {
            const { account, error } = this.state
            return (
                <AuthComp {...this.props} accountInfo={account} error={error} onSignIn={() => this.onSignIn()}
                    onSignOut={() => this.onSignOut()}
                />
            )
        }
    }
