import * as apiService from '../shared/apiService';

const receivedExamEnumsType = "RECEIVED_EXAM_ENUMS_TYPE";
const parsedSubjectsType = "PARSED_SUBJECTS_TYPE";
const updatedTopicsType = "UPDATED_TOPICS_TYPE";
const updatedSubTopicsType = "UPDATED_SUB_TOPICS_TYPE";
const updatePreferencesType = "UPDATED_PREFERENCES_TYPE";

var initialState = {
    fullExamEnums: [],
    subjects: [],
    topics: [],
    subTopics: [],
    preferences: {course: "9432e715-26e0-4151-8154-b04d298b18fe", subject: "", topics: [], subTopics: [], maxNumberOfQuestions: 5},
    //preferences: {course: "082ce194-5dab-486e-b1cd-3d9995c1effb", subject: "", topics: [], subTopics: [], maxNumberOfQuestions: 10},
};

export const actionCreators = {
    selectSubject: (newSubject) => async(dispatch, getState) => {
        dispatch({type: updatePreferencesType, preferences: initialState.preferences});
        dispatch({type: updatedTopicsType, topics: []});
        dispatch({type: updatedSubTopicsType, subTopics: []});

        var examEnums =  Array.from(getState().preExam.fullExamEnums);
        var topics = [];

        for(var examEnum of examEnums){
            if(examEnum.subject == newSubject && examEnum.partitionKey.includes("Topic") && !examEnum.partitionKey.includes("Sub"))
                topics.push(examEnum);
        }

        dispatch({type: updatedTopicsType, topics:topics});
        var preferences = Object.assign({}, getState().preExam.preferences);
        preferences["subject"] = newSubject;
        dispatch({type: updatePreferencesType, preferences: preferences});
    },

    updateTopicPreferences: (topics) => async(dispatch, getState) => {
        var preferences = Object.assign({}, getState().preExam.preferences);
        preferences["topics"] = topics;
        dispatch({type: updatePreferencesType, preferences});
    }
}

export const reducer = (state, action) => {
    state = state || initialState;


    if(action.type == receivedExamEnumsType){
        return {
            ...state,
            fullExamEnums: action.examEnums
        }
    }

    if(action.type == parsedSubjectsType){
        return {
            ...state,
            subjects: action.subjects
        }
    }

    if(action.type == updatedTopicsType){
        return{
            ...state, 
            topics: action.topics
        }
    }

    if(action.type == updatedSubTopicsType){
        return {
            ...state, 
            subTopics: action.subTopics
        }
    }

    if(action.type == updatePreferencesType){
        return{
            ...state, 
            preferences: action.preferences
        }
    }

    return state;
};

