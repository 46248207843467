import React, { Component, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/UserStats';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Loading from './shared/Loading';
import Button from '@material-ui/core/Button';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SendIcon from '@material-ui/icons/Send';
import { Link, BrowserRouter as Router } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import Grid from "@material-ui/core/Grid";
import * as commonService from '../shared/commonService';
import { ListItem, Typography } from '@material-ui/core';
import { PieChart } from 'react-minimal-pie-chart';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Moment from 'react-moment';
import { Line } from '@reactchartjs/react-chart.js';
import styled from 'styled-components';
import FormLabel from '@material-ui/core/FormLabel';
import Pagination from '@material-ui/lab/Pagination';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles((theme) => ({
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  listItemContent: {
    marginBottom: "1vh"
  },
  chart: {
    marginTop: "1vh"
  }
}));

class UserStats extends React.Component {

  componentWillMount() {

    if (this.props.location.subjectId != null && this.props.location.deckId != null) {
      this.props.fetchUserStats(this.props.location.subjectId, this.props.location.deckId);
    } else {
      this.props.history.push({
        pathname: "/",
      })
    }

    mixpanel.track("UserStats");
  }

  render() {
    if (this.props.location.subjectId != null) {
      if (this.props.loading == false) {
        if (this.props.exams.length > 0) {
          return (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
            }}>
              <StatsTabs {...this.props} />
              <Footer></Footer>
            </div>
          )
        } else {
          return (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
            }}>
              <Header></Header>
              <Grid container
                direction="column"
                justify="space-between"
                alignItems="center"
                style={{ position: "absolute", top: "50%", margin: '0px', transform: "translateY(-50%)" }}
              >
                <Grid item>
                  <img style={{ width: "80vw", height: "auto", maxWidth: "600px", marginBottom: "5vh" }} src="https://nebulaexamsenterprise.blob.core.windows.net/media/graphics/rover-new-bg.svg?sv=2019-12-12&st=2021-01-26T21%3A13%3A35Z&se=2023-01-27T21%3A13%3A00Z&sr=b&sp=r&sig=pIeGl7QPFg19V0HMfHSsXF99YoNiTv5hfECAERwbbVM%3D"></img>
                </Grid>
                <Grid item>
                  <Typography variant="h3" align="center" ><b>Nothing to report</b></Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" align="center" style={{ maxWidth: "600px", margin: "3vh 3vw 3vh 3vw" }}>Take an exam or get practicing to start tracking your progress</Typography>
                </Grid>
                <Grid item>
                  <Link
                    to={{
                      pathname: '/',
                    }}

                    style={{ textDecoration: "none" }}
                  ><Button style={{
                    borderWidth: "1.5px",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4.06612px 16.2645px"
                  }} color="secondary" variant="contained">Back To Home</Button></Link>
                </Grid>
              </Grid>
              <Footer></Footer>
            </div>
          )
        }

      } else {
        return (<Loading />)
      }
    } else {
      return (<Loading />)
    }


  }
}

function StatsTabs(props) {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const HoverText = styled.p`
  font-size:14px;
  :hover {
    font-weight: bold;
    cursor: pointer;
  }
`

  return (
    <div className={classes.root}>
      <LinkContainer to={{
        pathname: '/'
      }} style={{ marginTop: "10px" }}><FormLabel size="large"><HoverText >⇦ &nbsp; {props.subjectLabel.toUpperCase()}</HoverText></FormLabel></LinkContainer>
      <AppBar position="static" style={{ alignItems: 'center' }}>
        <Tabs value={tabIndex} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Exam Progress" {...a11yProps(0)} />
          <Tab label="History" {...a11yProps(1)} />
          <Tab label="Weekly Leaderboard" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <CentralizedContainer> <Charts scores={props.scores} timeTakenMinutes={props.timeTakenMinutes} /></CentralizedContainer>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <CentralizedContainer> <TakenExams exams={props.exams} /></CentralizedContainer>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <CentralizedContainer> <Leaderboard leaderboardHeaders={props.leaderboardHeaders} examLeaderboardRecords={props.examLeaderboardRecords} /></CentralizedContainer>
      </TabPanel>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function CentralizedContainer(props) {
  const { children } = props;
  return (
    <Grid container direction="row" justify="center" >
      <Grid item style={{ "width": "90vw", maxWidth: "800px" }}>
        {children}
      </Grid>
    </Grid>
  )
}

function TakenExams(props) {

  const classes = useStyles();
  const examsPerPage = 5;

  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <React.Fragment>

      <Paper style={{ position: "relative" }}>
        <List className={classes.list}
        >
          <Container >
            <Typography variant="h5" style={{ padding: "20px" }} align="center">Review your exams and practice sessions</Typography>
            <Divider />
            {props.exams.map((exam, index) => {

              return ((index < page * examsPerPage && index >= (page - 1) * examsPerPage) ? <TakenExam
                key={exam.id}
                numberOfExams={props.exams.length}
                index={index}
                timestamp={exam.timestamp}
                timeTakenMinutes={exam.timeTakenMinutes}
                score={exam.score}
                id={exam.id}
                examType={exam.examType}></TakenExam> : null)
            })}
          </Container>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Pagination onChange={handleChange} count={Math.ceil(props.exams.length / examsPerPage)} style={{ paddingTop: "1vh", paddingBottom: "1vh", textAlign: "center" }} size="large" color="secondary" />
            </Grid>
          </Grid>
        </List>
      </Paper>
    </React.Fragment>
  )
}

function TakenExam(props) {
  return (
    <React.Fragment>
      <List>
        <ListItem>
          <ListItemAvatar > <img width="20px" src="https://nebulaexamsenterprise.blob.core.windows.net/media/graphics/calendar.svg?sv=2019-10-10&st=2020-11-01T18%3A13%3A05Z&se=2022-11-02T18%3A13%3A00Z&sr=b&sp=r&sig=aS14fSDacqQcbptgvEnzZHoRHOsE4RoV7FlkFR8abQg%3D" />
          </ListItemAvatar>
          <Typography style={{ fontSize: "1.75em" }}><Moment format="LL">{props.timestamp}</Moment></Typography>
        </ListItem>
        <ListItem>
          <ListItemAvatar >            <img width="20px" src="https://nebulaexamsenterprise.blob.core.windows.net/media/graphics/pencil-cup.svg?sv=2019-12-12&st=2021-01-26T21%3A43%3A14Z&se=2023-01-27T21%3A43%3A00Z&sr=b&sp=r&sig=DE8mGhuiUr9vu3qYKqvAUsA2zNzq8JH75OvH6mxVdUQ%3D" />
          </ListItemAvatar>
          <Typography style={{ fontSize: "1.75em" }}>Type: {props.examType}</Typography>
        </ListItem>
        <ListItem>
          <ListItemAvatar >
            <img width="20px" src="https://nebulaexamsenterprise.blob.core.windows.net/media/graphics/target%20(1).svg?sv=2019-10-10&st=2020-11-01T18%3A33%3A14Z&se=2022-11-02T18%3A33%3A00Z&sr=b&sp=r&sig=UZPR%2BOWMtzG%2BmGA6ChvqaS3FNJ%2BlO%2FWkmK6NgUVRrP4%3D" />
          </ListItemAvatar>
          <Typography style={{ fontSize: "1.75em" }}>Score: {Math.ceil(props.score)} %</Typography>
        </ListItem>
        <ListItem>
          <ListItemAvatar >
            <img width="20px" src="https://nebulaexamsenterprise.blob.core.windows.net/media/graphics/hourglass.svg?sv=2019-10-10&st=2020-11-01T18%3A32%3A47Z&se=2022-11-02T18%3A32%3A00Z&sr=b&sp=r&sig=4tsZeANblc5v1RIjH9XPUlu5epBItgFjNJYLSwKcAr8%3D" />
          </ListItemAvatar>
          <Typography style={{ fontSize: "1.75em" }}>Time taken: {Math.ceil(props.timeTakenMinutes)} minutes</Typography>
          <ListItemSecondaryAction>
            <LinkContainer to={{
              pathname: '/ExamReview',
              redirectedFromStatsPage: true,
              examId: props.id,
              practice: false
            }}

              style={{ textDecoration: "none", color: "white", fontSize: "1.2em" }}><Button color="secondary" variant="contained">Review</Button></LinkContainer>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Divider />
    </React.Fragment>
  )
}

function Charts(props) {

  return (
    <React.Fragment>
      <Paper>
        <Typography variant="h5" style={{ padding: "20px" }} align="center">Track the progress of your exam scores</Typography>
        <Divider />
        <Grid container direction="column" >
          <CorrectChart scores={props.scores} />
          <Divider style={{ margin: "20px" }} />
          <TimeChart timeTakenMinutes={props.timeTakenMinutes} />
        </Grid>
      </Paper>

    </React.Fragment>

  )
}

function CorrectChart(props) {

  const classes = useStyles();

  const data = {
    datasets: [
      {
        label: 'Score / Date',
        data: props.scores,
        fill: false,
        backgroundColor: 'rgba(217, 30, 24, 1)',
        borderColor: 'rgba(217, 30, 24, 0.1)',
      },
    ],
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: "Marks Awarded (%)"
          }
        },
      ],
      xAxes: [
        {
          type: 'time',
          time: {
            unit: 'day',
            unitStepSize: 1,
            displayFormats: {
              'day': 'MMM DD'
            }
          },
          scaleLabel: {
            display: true,
            labelString: "Date"
          }
        },
      ],
    },
  }

  return (
    <Line data={data} options={options} />
  )
}

function TimeChart(props) {

  const classes = useStyles();

  const data = {
    datasets: [
      {
        label: 'Time taken (minutes) / Date',
        data: props.timeTakenMinutes,
        fill: false,
        backgroundColor: 'rgba(30, 130, 76, 1)',
        borderColor: 'rgba(30, 130, 76, 0.2)',
      },
    ],
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: "Time Taken (minutes)"
          }
        },
      ],
      xAxes: [
        {
          type: 'time',
          time: {
            unit: 'day',
            unitStepSize: 1,
            displayFormats: {
              'day': 'MMM DD'
            }
          },
          scaleLabel: {
            display: true,
            labelString: "Date"
          }
        },
      ],
    },
  }

  return (
    <Line data={data} options={options} />
  )
}

function Leaderboard(props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Typography variant="h5" style={{ padding: "20px" }} align="center">Compare your exam scores to others in a weekly leaderboard</Typography>
      <Divider />
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.leaderboardHeaders.map((header, index) => (
              <TableCell key={index} ><Typography>{header}</Typography></TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.examLeaderboardRecords.map((record, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography> {index + 1} </Typography>
              </TableCell>
              <TableCell ><Typography> {record.name}</Typography></TableCell>
              <TableCell ><Typography> {record.mostMarksAwarded} </Typography></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default connect(
  state => state.userStats,
  dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(UserStats);
