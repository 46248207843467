import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MathJaxContent from './MathJaxContent';

export default function TableContent({rows}){

    return(
        <TableContainer>
            <Table>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            {
                                row.map((cell) => (
                                    <TableCell><MathJaxContent text={cell}/></TableCell>
                                ))
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}