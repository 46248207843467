import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent';
import * as answerService from '../../../shared/answerService';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    textInput: {
        width: "80vw",
        maxWidth: "600px"
    }
    
  });

export default function Text(props){

    const classes = useStyles();

    const handleChange = (event) => {
        answerService.setAnswerValue(props.currentQuestion.id, event.target.value);
    };

    const handleKeyPress = (event) => {
        if(event.key === "Enter"){

            if(props.currentQuestion.parentId == null){
                props.submitAnswer(props.examId, props.isPractice)
            }
        }
    }

    return(
        <Grid item>
                <CardContent>
                    {props.isReview ? <TextField className={classes.textInput} id="standard-basic" placeholder="Answer" variant="outlined" onChange={handleChange} disabled={true} value={props.currentQuestion.userAnswer}/>
                     :  <TextField className={classes.textInput} id="standard-basic" variant="outlined" placeholder="Answer" onChange={handleChange} onKeyPress={handleKeyPress} disabled={false}/>}
                </CardContent>
        </Grid>
    )
}
