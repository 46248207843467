import React, { Component, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/MoreDetailsDeck';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Loading from './shared/Loading';
import { LinkContainer } from 'react-router-bootstrap';
import Grid from "@material-ui/core/Grid";
import { ListItem, Typography } from '@material-ui/core';
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Moment from 'react-moment';
import styled from 'styled-components';
import FormLabel from '@material-ui/core/FormLabel';
import Rating from '@material-ui/lab/Rating';



const useStyles = makeStyles((theme) => ({
    list: {
        backgroundColor: theme.palette.background.paper,
    },
    listItemContent: {
        marginBottom: "1vh"
    },
    listItemNoVerticalPadding: {
        padding: "0px 16px 0px 16px"
    }
}));

class MoreDetailsDeck extends React.Component {

    componentWillMount() {

        if (this.props.location.deckId == null) {
            this.props.history.push({
                pathname: "/"
            })
        }

        this.props.fetchDeckInfo(this.props.location.deckId);
        this.props.fetchRateReviews(this.props.location.deckId);
    }

    render() {
        if (this.props.loading == false) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}>
                    <Header></Header>
                    <Grid container
                        alignItems="flex-start"
                        justify="space-evenly"
                        style={{ marginTop: "12vh", marginBottom: "2vh" }}
                    >
                        <Grid item xs={10} md={8} lg={4} style={{ marginBottom: "5vh" }}>
                            <AboutSection deckInfo={this.props.deckInfo} />
                        </Grid>
                        <Grid item xs={10} md={8} lg={4}>
                            <RateReviews rateReviews={this.props.rateReviews} averageRating={this.props.averageRating} />
                        </Grid>
                    </Grid>
                    <Footer></Footer>
                </div>
            )
        } else {
            return (<Loading />)
        }

    }
}

function AboutSection(props) {

    const classes = useStyles();

    const HoverText = styled.p`
    font-size:14px;
    :hover {
      font-weight: bold;
      cursor: pointer;
    }
  `

    return (
        <React.Fragment>
            <LinkContainer to={{
                pathname: '/'
            }}><FormLabel size="large"><HoverText >⇦ Back To Home</HoverText></FormLabel></LinkContainer>

            <Paper>
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                        <img src={props.deckInfo.logoPath} style={{ width: "110px", height: "90px" }} />
                    </Grid>
                    <Grid item>
                        <Typography align="center" variant="h4"><b>{props.deckInfo.name}</b></Typography>
                    </Grid>
                    {/* <Grid item>
                        <Rating size="large" name="half-rating" readOnly value={props.averageRating} defaultValue={0} precision={0.5} style={{fontSize: "3rem"}} />
                    </Grid> */}
                </Grid>
                <Grid container direction="column" alignItems="flex-start">
                    <Grid item style={{ margin: "2vh 20px 0 20px" }}>
                        <Typography variant="h6"><b>About the publisher</b></Typography>
                    </Grid>
                    <Grid item style={{ margin: "0 20px 0vh 20px" }}>
                        <Typography variant="body1">{props.deckInfo.aboutPublisher}</Typography>
                    </Grid>
                    <Grid item style={{ margin: "2vh 20px 0 20px" }}>
                        <Typography variant="h6"><b>About the deck</b></Typography>
                    </Grid>
                    <Grid item style={{ margin: "0 20px 2vh 20px" }}>
                        <Typography variant="body1">{props.deckInfo.aboutDeck}</Typography>
                    </Grid>
                </Grid>
            </Paper>

        </React.Fragment>
    )
}

function RateReviews(props) {

    const classes = useStyles();

    return (
        <React.Fragment>

            <Paper style={{ position: "relative" }}>
                <Grid container alignItems="center" justify="center" direction="row">
                    <Grid item style={{ padding: "5px" }}>
                        <Rating size="large" name="half-rating" readOnly value={props.averageRating} defaultValue={0} precision={0.5} style={{ fontSize: "3rem" }} />
                    </Grid>
                    <Grid item style={{ padding: "5px" }}>
                        <Typography >({props.rateReviews.length} Reviews)</Typography>
                    </Grid>
                </Grid>
                <List className={classes.list}
                >
                    <Container >
                        <Divider />
                        {props.rateReviews.map((rateReview, index) => {
                            return (<RateReview rateReview={rateReview}></RateReview>);
                        })}
                    </Container>
                    {/* <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item>
                            <Pagination onChange={handleChange} count={Math.ceil(props.exams.length / examsPerPage)} style={{ paddingTop: "1vh", paddingBottom: "1vh", textAlign: "center" }} size="large" color="secondary" />
                        </Grid>
                    </Grid> */}
                </List>
            </Paper>
        </React.Fragment>
    )
}

function RateReview(props) {

    const classes = useStyles();

    return (
        <React.Fragment>
            <List>
                <ListItem className={classes.listItemNoVerticalPadding}>
                    <Typography variant="h6"><b>{props.rateReview.userName}</b></Typography>
                </ListItem>
                <ListItem className={classes.listItemNoVerticalPadding}>
                    <Rating size="large" name="half-rating" readOnly value={props.rateReview.rating} defaultValue={0} precision={0.5} />
                </ListItem>
                <ListItem>
                    <Typography variant="caption"><Moment format="LL">{props.rateReview.timestamp}</Moment></Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1">" {props.rateReview.review} "</Typography>
                </ListItem>
            </List>
            <Divider />
        </React.Fragment>
    )
}

export default connect(
    state => state.moreDetailsDeck,
    dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(MoreDetailsDeck);
