import { Typography } from '@material-ui/core';
import React, { Component, useEffect } from 'react';
import { Line, parse } from 'tryal-ui';

class TryalLine extends React.Component {

    componentDidMount() {
        const element = document.getElementById(this.props.lineId);
        const comp = new Line({
            target: element,
        });

        if (this.props.isReview) {
            this.handleReview();
        }
    }

    handleReview = () => {
        var submittedInput = this.props.submittedValue;
        var lineElement = document.getElementById(this.props.lineId);

        if (lineElement != null) {
            var sampleInputElement = lineElement.children[0].cloneNode(true);

            lineElement.appendChild(sampleInputElement);

            var lineInput = lineElement.getElementsByTagName("input")[0];
            lineInput.value = submittedInput;
            let result = lineInput.dispatchEvent(new Event('input'));

            try{
                parse(submittedInput);
            }catch(err){
                console.log(err);
                let parent = lineInput.parentElement;
                let toggle = parent.getElementsByTagName("button")[0];
                toggle.dispatchEvent(new Event("click"));
            }

            lineInput.disabled = true;

            lineElement.removeChild(lineElement.lastChild);
        }
    }

    render() {
        return (
            <div id={this.props.lineId} style={{ fontSize: "1.5rem" }}></div>
        )
    }
}

export default TryalLine;