import * as commonService from '../shared/commonService';
import * as apiService from '../shared/apiService';

var localStorage = window.localStorage;

const fetchExamType = "FETCH_ANSWERED_QUESTIONS_TYPE";
const loading = "LOADING";

var initialState = {
    total: 0,
    marksAwarded: 0,
    marksAvailable: 0,
    timeTakenSeconds: 0,
    loading: true
}

export const actionCreators = {
    fetchExam: (examId) => async(dispatch, getState) => {

        dispatch({type: loading, loading: true});
        var userId = localStorage.getItem("userId");
        var data = {userId, examId};

        apiService.fetchExam(data).then(function(response) {

            dispatch({type: loading, loading: false});

            var exam = response;
            var total = exam.numberOfQuestions;
            var marksAwarded = exam.marksAwarded;
            var marksAvailable = exam.marksAvailable;
            var timeTakenSeconds = exam.timeTakenSeconds;
    
            dispatch({ type: fetchExamType, total, marksAwarded, marksAvailable, timeTakenSeconds });
        });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type == fetchExamType) {
        return {
            ...state,
            total: action.total,
            marksAwarded: action.marksAwarded,
            marksAvailable: action.marksAvailable,
            timeTakenSeconds: action.timeTakenSeconds
        }
    }

    if(action.type == loading){
        return{
            ...state,
            loading: action.loading
        }
    }

    return state;
};