import { Typography } from '@material-ui/core';
import React, { Component, useEffect } from 'react';
import { Workings, Line } from 'tryal-ui';
import TryalLine from './TryalLine';
import * as answerService from '../../../shared/answerService';
import uuid from "uuid";

class TryalWorkings extends React.Component {

    constructor(props) {
        super(props);
        this.state = { submittedWorkings: [] }
    }

    componentDidMount() {

        if (this.props.isReview == true) {

            let submittedWorkings = this.props.currentQuestion.userAnswer;
            submittedWorkings != null ? submittedWorkings : [];
            this.setState({ submittedWorkings: submittedWorkings });

        } else {
            const element = document.getElementById(this.props.workingsId);
            const comp = new Workings({
                target: element,
            });

            comp.$on('update', (event) => {
                console.log(event.detail);

                answerService.setAnswerValue(this.props.currentQuestion.id, event.detail.workings);
            });
        }
    }

    render() {

        if (this.props.isReview) {
            return (
                <React.Fragment>
                    {Array.isArray(this.state.submittedWorkings) ? (this.state.submittedWorkings.map((submittedWorking) => {
                        return <TryalLine lineId={uuid.v4()} submittedValue={submittedWorking} isReview={true} />
                    })) :  ( <TryalLine lineId={uuid.v4()} submittedValue="" isReview={true} />)}
                </React.Fragment>
            )
        } else {
            return (
                <div id={this.props.workingsId} style={{ fontSize: "1.5rem" }}></div>
            )
        }
    }

}

export default TryalWorkings;