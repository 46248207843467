import * as apiService from '../shared/apiService';

var localStorage = window.localStorage;

const fetchRateReviewsType = "FETCH_RATE_REVIEWS_TYPE";
const fetchDeckInfoType = "FETCH_DECK_INFO_TYPE";
const rateReviewLoading = "RATE_REVIEW_LOADING";
const fetchDeckInfoLoading = "FETCH_DECK_INFO_LOADING";

var initialState = {
    rateReviews: [],
    deckInfo: {name: "", aboutPublisher: "", aboutDeck: ""},
    averageRating: 0,
    rateReviewsLoading: true,
    fetchDeckInfoLoading: true
}

export const actionCreators = {
    fetchRateReviews: (deckId) => async(dispatch, getState) => {

        dispatch({type: rateReviewLoading, loading: true});

        var data = {deckId};

        apiService.fetchRateReviews(data).then(function(response) {

            dispatch({type: rateReviewLoading, loading: false});
            dispatch({ type: fetchRateReviewsType, rateReviews: response.rateReviews, averageRating: response.averageRating});
        });
    },
    fetchDeckInfo: (deckId) => async(dispatch, getState) => {

        dispatch({type: fetchDeckInfoLoading, loading: true});

        var data = {deckId};

        apiService.fetchDeckInfo(data).then(function(response) {

            dispatch({type: fetchDeckInfoLoading, loading: false});
            dispatch({ type: fetchDeckInfoType, deckInfo: response.deckInfo});
        });
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type == fetchRateReviewsType) {
        return {
            ...state,
            rateReviews: action.rateReviews,
            averageRating: action.averageRating
        }
    }

    if(action.type == fetchDeckInfoType) {
        return{
            ...state, 
            deckInfo: action.deckInfo
        }
    }

    if(action.type == rateReviewLoading){
        return{
            ...state,
            loading: action.loading
        }
    }

    if(action.type == fetchDeckInfoLoading){
        return{
            ...state,
            loading: action.loading
        }
    }

    return state;
};