const config = window.MyConfig;

config.apiSubscriptionKey = configValueHasBeenSet(config.apiSubscriptionKey) ? config.apiSubscriptionKey : "31f3276d06e9441fa76573b825dbdce0";
config.clientId = configValueHasBeenSet(config.clientId) ? config.clientId : "3b0d8a06-1bc7-481f-9ca1-1cb4dd32ad74";
config.authority = configValueHasBeenSet(config.authority) ? config.authority : "https://nebulaexamsdev.b2clogin.com/nebulaexamsdev.onmicrosoft.com/B2C_1_nebula-dev-signin-signup";
config.redirectUri = configValueHasBeenSet(config.redirectUri) ? config.redirectUri : "http://localhost:3000";
config.examBaseUrl = configValueHasBeenSet(config.examBaseUrl) ? config.examBaseUrl : "https://nebula-apim.azure-api.net/nebula-exam-api-dev/";
//config.examBaseUrl = configValueHasBeenSet(config.examBaseUrl) ? config.examBaseUrl : "http://localhost:7072/api/";
//config.userBaseUrl = configValueHasBeenSet(config.userBaseUrl) ? config.userBaseUrl : "http://localhost:7071/api/";
config.userBaseUrl = configValueHasBeenSet(config.userBaseUrl) ? config.userBaseUrl : "https://nebula-apim.azure-api.net/nebula-user-api-dev/";
//config.publisherBaseUrl = configValueHasBeenSet(config.publisherBaseUrl) ? config.publisherBaseUrl : "http://localhost:7071/api/";
config.publisherBaseUrl = configValueHasBeenSet(config.publisherBaseUrl) ? config.publisherBaseUrl : "https://nebula-apim.azure-api.net/nebula-publisher-api-dev/";

export default config;

function configValueHasBeenSet(value) {
    if (value == null || value == '' || value == undefined) return false
  
    var regPattern = /#(.*?)#/
  
    return !regPattern.test(value)
}