import Promise from 'promise'

export const postData = (url = '', data = {}) => {
    return new Promise(async function(resolve, reject){
        const response = await fetch(url, {
            method: 'POST', 
            mode: 'cors', 
            cache: 'no-cache', 
            credentials: 'same-origin', 
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            redirect: 'follow', 
            referrer: 'no-referrer', 
            body: JSON.stringify(data) 
        });

        if(response.status == 200){
            resolve(response);
        }else{
            reject(response);
        }
    });
}

export function isReview(){
    var url = window.location.href;

    if(url.toLowerCase().includes("review")){
        return true;
    }else{
        return false;
    }
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

