import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import Text from './Text';
import Image from './Image';
import MathJaxContent from './MathJaxContent';
import List from './List';
import TableContent from './TableContent';
import uuid from 'uuid'

export default function QuestionContent({ currentQuestion }) {
    return (
        <React.Fragment>
            {currentQuestion.questionContent.map((questionContentItem, index) => {
                return (<Grid key={index} item><QuestionContentItem questionContentItem={questionContentItem} /></Grid>)
            })}
        </React.Fragment>
    )
}

export function QuestionContentItem({ questionContentItem }) {
    switch (questionContentItem.type) {
        case "text":
            return <Text text={questionContentItem.text} id={uuid.v4()} />
        case "image":
            return <Image url={questionContentItem.url} binaryString={questionContentItem.data} />
        case "mathjax":
            return <Text text={questionContentItem.text} id={uuid.v4()} />
        case "list":
            return <List listValues={questionContentItem.values} />
        case "table":
            return <TableContent rows={questionContentItem.rows}/>
        default:
            return <React.Fragment />
    }
}