import React, { Component, useEffect } from 'react';
import { Line } from 'tryal-ui';

class TableLineInput extends React.Component {

    componentDidMount(){
        const element = document.getElementById(this.props.lineId);
        const comp = new Line({
            target: element,
        });
    }

    componentDidUpdate(prevProps){
        if(this.props.isReview){
            if(this.props.isReview != prevProps.isReview){
                this.handleReview();
            }
        }
    }

    handleReview = () => {
        var submittedInput = this.props.cell.value;
        var lineElement = document.getElementById(this.props.lineId);

        if(lineElement != null){
            var sampleInputElement = lineElement.children[0].cloneNode(true);

            lineElement.appendChild(sampleInputElement);
    
            var lineInput = lineElement.getElementsByTagName("input")[0];
            lineInput.value = submittedInput;
            lineInput.dispatchEvent(new Event('input'));
            lineInput.disabled = true;

            lineElement.removeChild(lineElement.lastChild);
        }
    }

    render(){
        return(
            <div id={this.props.lineId} style={{fontSize: "1.5rem"}}></div>
        )
    }
}

export default TableLineInput;