import * as apiService from '../shared/apiService';

var localStorage = window.localStorage;

const fetchUserStats = "FETCH_EXAMS_TYPE";
const loading = "LOADING";

var initialState = {
    exams: [],
    loading: true,
    scores: [],
    timeTakenMinutes: [],
    subjectLabel: ""
}

export const actionCreators = {
    fetchUserStats: (subjectId, deckId) => async(dispatch, getState) => {

        dispatch({type: loading, loading: true});
        var userId = localStorage.getItem("userId");
        var data = {userId, subjectId, deckId};

        apiService.fetchUserStats(data).then(function(response) {

            dispatch({type: loading, loading: false});
            dispatch({ type: fetchUserStats, exams: response.takenExams,
                 scores: response.scores, 
                 timeTakenMinutes: response.timeTakenMinutes,
                leaderboardHeaders: response.leaderboardHeaders,
                examLeaderboardRecords: response.examLeaderboardRecords,
                subjectLabel: response.subjectLabel
            });
        });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type == fetchUserStats) {
        return {
            ...state,
            exams: action.exams,
            scores: action.scores,
            timeTakenMinutes: action.timeTakenMinutes,
            leaderboardHeaders: action.leaderboardHeaders,
            examLeaderboardRecords: action.examLeaderboardRecords,
            subjectLabel: action.subjectLabel
        }
    }

    if(action.type == loading){
        return{
            ...state,
            loading: action.loading
        }
    }

    return state;
};