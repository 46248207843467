import React from 'react';
import { Route, useLocation } from 'react-router';
import PreExam from './components/PreExam';
import Exam from './components/Exam';
import ExamResult from './components/ExamResult';
import ExamReview from './components/ExamReview';
import Deck from './components/Deck';
import UserStats from './components/UserStats';
import AuthProvider from './AuthProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { purple } from '@material-ui/core/colors';
import {Box, CssBaseline, MuiThemeProvider} from '@material-ui/core';
import MoreDetailsDeck from './components/MoreDetailsDeck';
import BenchmarkExam from './components/BenchmarkExam';
import PreBenchmarkExam from './components/PreBenchmarkExam';
import BenchmarkExamSubmission from './components/BenchmarkExamSubmission';
import * as apiService from './shared/apiService';

// async function configureThemeConfig(){

//     var primaryColour = localStorage.getItem("primaryColour");
//     var secondaryColour = localStorage.getItem("secondaryColour");
//     var headerImagePath = localStorage.getItem("headerImagePath");

//     if(localStorage.getItem("lastSelectedDeckId") == null){
//       if(localStorage.getItem("userId") != null){
//         await apiService.fetchUser({userId: localStorage.getItem("userId")}).then(async function(result){
//           if(result.decks != null){
//             if(result.decks.length > 0){
//               await stashThemeConfig(result.decks[0].id, primaryColour, secondaryColour, headerImagePath);
//             }
//           }
//         }).catch(function(result){
  
//         })
//       }
//     }else{
//       await stashThemeConfig(localStorage.getItem("lastSelectedDeckId"), primaryColour, secondaryColour, headerImagePath);
//     }

// }

// async function stashThemeConfig(deckId, primaryColour, secondaryColour, headerImagePath){
//   await apiService.fetchDeckInfo({deckId}).then(function(result){

//     var themeConfig = JSON.parse(result.deckInfo.theme);

//     localStorage.setItem("primaryColour", themeConfig.primaryColour);
//     localStorage.setItem("secondaryColour", themeConfig.secondaryColour);
//     localStorage.setItem("headerImagePath", themeConfig.headerImagePath);

//     if(themeConfig.primaryColour != primaryColour || themeConfig.secondaryColour != secondaryColour || themeConfig.headerImagePath != headerImagePath){
//       window.location.reload();
//     }
//   })
// }

var primaryColour = localStorage.getItem("primaryColour") != null ? localStorage.getItem("primaryColour") : "#433049"
var secondaryColour = localStorage.getItem("secondaryColour") != null ? localStorage.getItem("secondaryColour") : "#AA5088"

const montserrat = {
  fontFamily: 'MontserratMedium',
  fontStyle: 'normal',
  fontWeight: 400, 
  src: `
  url(https://nebulaexamsenterprise.blob.core.windows.net/fonts/Montserrat-Medium.woff?sv=2019-12-12&st=2021-02-04T18%3A00%3A13Z&se=2023-02-05T18%3A00%3A00Z&sr=b&sp=r&sig=75iq3Gjkwp8Bt%2B%2BY5Chi%2BtwgR7vJXhiqSjTITADv9Co%3D) format('woff')
  `, /* Modern Browsers */
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const theme = createMuiTheme({
    palette: {
      primary: {
        // main:  "#433049"
        main: primaryColour
      },
      secondary: {
        main: secondaryColour
      },
      tertiary: {
          main: "#786FB7"
      },
      background: {
          main: "#E4E2EA"
      }
    },
    typography:{
        "fontFamily": "Montserrat, MontserratMedium",
        body1: {
          "fontSize": "1.75em"
        },
        caption: {
          "fontSize": "1.2em"
        },
        button: {
          "fontSize": "1.2em"
        }, 
        h3: {
          "fontSize": "3.5em"
        },
        h4: {
          "fontSize": "3.25em"
        },
        h5: {
          "fontSize": "3em"
        },
        h6: {
          "fontSize": "2.75em"
        }

    }, 
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [montserrat],
        },
      },
    },
  });

class App extends React.Component {
    render() {

      if(this.props.accountInfo != null){

        //configureThemeConfig();

        return (
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
                  <Route exact path="/" render={(props) => (
                    <Deck {...this.props} />
                  )} />
                  <Route path='/PreExam' component={PreExam} />
                  <Route path='/Deck' component={Deck} />
                  <Route path='/Exam' component={Exam} />
                  <Route path="/ExamResult" component={ExamResult} />
                  <Route path="/ExamReview" component={ExamReview} />
                  <Route path="/UserStats" component={UserStats} />
                  <Route path="/MoreDetailsDeck" component={MoreDetailsDeck} />
                  <Route path="/BenchmarkExam" component={BenchmarkExam} />
                  <Route path="/PreBenchmarkExam" component={PreBenchmarkExam} />
                  <Route path="/BenchmarkExamSubmission" component={BenchmarkExamSubmission} />
          </MuiThemeProvider>
      )
      }else{
        return(
          <div><p>Redirecting...</p></div>
        )
      }
    }
}

export default AuthProvider(App);
