import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import * as apiService from '../shared/apiService';
import * as answerService from '../shared/answerService';
import * as commonService from '../shared/commonService';

var localStorage = window.localStorage;

var initialState = {
    questions: [],
    currentQuestion: { id: uuidv4(), question: "", options: [], layoutType: -1, relativeImagePath: "", startOfQuestion: moment(), difficulty: -1 },
    redirectToReview: false,
    isReview: false,
    currentSubTopicIndex: -1,
    subTopicLabel: "",
    totalSubTopics: 0
}

var submittedSubTopicQuestions = [];
var fetchExamRequestBody = {};
var subTopicData = {};

const updateCurrentQuestionType = "UPDATE_CURRENT_QUESTION_TYPE";
const fireRedirect = "FIRE_REDIRECT_TYPE";
const toggleLoadingType = "TOGGLE_LOADING_TYPE";
const clear = "CLEAR";
const updateSubTopicDetails = "UPDATE_SUB_TOPIC_DETAILS";

export const actionCreators = {

    fetchExamQuestions: (preferences, isPractice) => async (dispatch, getState) => {
        dispatch({ type: toggleLoadingType, loading: true });

        fetchExamRequestBody = preferences;

        nextSubTopic(dispatch);
    },

    submitGroupedAnswer: (examId, isPractice) => async (dispatch, getState) => {
        var currentQuestionGroup = Object.assign({}, getState().exam.currentQuestion);
        var questions = currentQuestionGroup.questions;
        var submittedAnswers = [];
        var submission = {
            templateSeed: currentQuestionGroup.templateSeed, 
            externalId: currentQuestionGroup.externalId, 
            marksAvailable: currentQuestionGroup.marks,
            deckId: currentQuestionGroup.deck};

        for (var currentQuestion of questions) {
            var questionId = currentQuestion.id;
            var answerValue = answerService.getAnswerValue(questionId);
            answerValue = answerValue == undefined ? "" : answerValue;

            submittedAnswers.push({
                id: uuidv4(),
                questionId: currentQuestion.id,
                userAnswer: answerValue
            });
        }

        submission["submittedAnswers"] = submittedAnswers;

        var marksAwarded = await answerService.processAnsweredQuestion(submission);
        var marksAwardedPercent = marksAwarded / currentQuestionGroup.marks;

        if (marksAwardedPercent > 0.5) {
            nextQuestion(dispatch, currentQuestion, currentQuestion.priority + 1)
        } else {
            nextQuestion(dispatch, currentQuestion, currentQuestion.priority - 1)
        }
    },

    clear: () => async (dispatch, getState) => {
        answerService.resetAnswerValue();
        dispatch({ type: clear });
    }
}

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type == clear) {
        return {
            currentQuestion: { id: uuidv4(), question: "", options: [], layoutType: -1, relativeImagePath: "", startOfQuestion: moment(), difficulty: -1 },
            redirectToReview: false,
        }
    }

    if (action.type == toggleLoadingType) {
        return {
            ...state,
            loading: action.loading
        }
    }

    if (action.type == updateCurrentQuestionType) {
        return {
            ...state,
            currentQuestion: action.currentQuestion
        }
    }

    if (action.type == fireRedirect) {
        return {
            ...state,
            redirectToReview: true
        }
    }

    if(action.type == updateSubTopicDetails){
        return{ 
            ...state, 
            currentSubTopicIndex: action.currentSubTopicIndex,
            totalSubTopics: action.totalSubTopics,
            subTopicLabel: action.subTopicLabel
        }
    }

    return state;
}

function nextQuestion(dispatch, currentQuestion, nextPriority) {
    var nextQuestions = subTopicData.questions.filter(x => x.priority == nextPriority)

    if (nextQuestions.length > 0) {
        var indexOfQuestion = indexOfAttr(subTopicData.questions, "id", currentQuestion.id);
        subTopicData.questions.splice(indexOfQuestion, 1);

        dispatch({ type: updateCurrentQuestionType, currentQuestion: nextQuestions[0] });
    } else {

        apiService.benchmarkSubmission({
            deckId: currentQuestion.deck,
            subjectId: currentQuestion.subject,
            topicId: currentQuestion.topic,
            subTopicId: currentQuestion.subTopicId,
            submittedQuestions: submittedSubTopicQuestions
        }).then(function (response) {

            nextSubTopic(dispatch);
        })
    }
}

function nextSubTopic(dispatch) {

    apiService.fetchBenchmarkExamQuestions(fetchExamRequestBody).then(async function (response) {
        subTopicData = response;

        if(subTopicData == null){
            dispatch({ type: fireRedirect });
        }else{
            dispatch({ type: updateSubTopicDetails, currentSubTopicIndex: subTopicData.submittedSubTopicCount, 
                totalSubTopics: subTopicData.totalSubTopicCount,  
                subTopicLabel: subTopicData.label
            })
            dispatch({ type: updateCurrentQuestionType, currentQuestion: subTopicData.questions[0] });
            dispatch({ type: toggleLoadingType, loading: false });
        }

    }).catch(err => {

    })
}

function sortQuestionsByPriority(a, b) {
    return a.priority > b.priority;
}


function arraysEqual(_arr1, _arr2) {

    if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length)
        return false;

    var arr1 = _arr1.concat().sort();
    var arr2 = _arr2.concat().sort();

    for (var i = 0; i < arr1.length; i++) {

        if (arr1[i] !== arr2[i])
            return false;

    }

    return true;
}

function indexOfAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}