import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CardContent from '@material-ui/core/CardContent';
import * as answerService from '../../../shared/answerService';
import uuid from 'uuid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Latex from '../Misc/Latex';


export default function MultipleChoice(props){
    const [optionsValue, setOptionsValue] = React.useState("");

  const handleChange = (event) => {
    setOptionsValue(event.target.value);
    answerService.setAnswerValue(props.currentQuestion.id, event.target.value);
  };

  return (
      <Grid item>
              <CardContent>
                    <FormControl component="fieldset" disabled={props.isReview}>
                    <FormLabel component="legend" style={{marginBottom: '0px'}}>Possible Answers</FormLabel>
                    {
                        props.isReview ? 
                        <RadioGroup row aria-label="possible answers" name="possibleAnswers" value={props.currentQuestion.userAnswer}>
                            <AnswerOptions key={uuid.v4()} options={props.currentQuestion.options} />
                        </RadioGroup> :
                        <RadioGroup row aria-label="possible answers" name="possibleAnswers" value={optionsValue} onChange={handleChange}>
                            <AnswerOptions key={uuid.v4()} options={props.currentQuestion.options} />
                        </RadioGroup>
                    }
                    
                    </FormControl>
              </CardContent>
        </Grid>
  )
}

function AnswerOptions(props){

    const options = props.options.map((option) => {
        return (<Latex><FormControlLabel key={option} value={option} control={<Radio />} label={option} /></Latex>)
    })

    return (
        <React.Fragment>{options}</React.Fragment>
    )
}