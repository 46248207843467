// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dH_XdeAO-OXTVhE9qrWnt{\r\n    background-color: white;\r\n    height: 140px;\r\n}", "",{"version":3,"sources":["webpack://./src/layout.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;AACjB","sourcesContent":[".jumbotron{\r\n    background-color: white;\r\n    height: 140px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"jumbotron": "dH_XdeAO-OXTVhE9qrWnt"
};
export default ___CSS_LOADER_EXPORT___;
