import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/ExamResult';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Loading from './shared/Loading';
import Button from '@material-ui/core/Button';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SendIcon from '@material-ui/icons/Send';
import { Link, BrowserRouter as Router } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import Grid from "@material-ui/core/Grid";
import * as commonService from '../shared/commonService';
import { Typography } from '@material-ui/core';
import { PieChart } from 'react-minimal-pie-chart';
import { withOrientationChange } from 'react-device-detect';


class ExamResult extends React.Component {

    componentWillMount() {
        mixpanel.track("ExamResult");
        this.props.fetchExam(this.props.location.examId);
    }

    render() {

            if (this.props.loading == false) {
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                    }}>
                        <Header></Header>
                        <Grid container
                            direction="column"
                            alignItems="center"
                            style={{ marginTop: "20vh", marginBottom: "2vh" }}
                        >
                            <ExamResultContent {...this.props} />
                        </Grid>
                        <Footer></Footer>
                    </div>
                )
            } else {
                return (<Loading />)
            }

    }
}

function ExamResultContent(props) {
    return (
        <React.Fragment>
            <Grid item>
                <PieChart
                    data={[
                        { title: 'Marks Awarded', value: props.marksAwarded, color: 'rgba(170, 80, 136, 1)' },
                        { title: 'Marks Not Awarded', value: props.marksAvailable - props.marksAwarded, color: 'rgba(170, 80, 136, 0.35)' }
                    ]}
                    lineWidth={50}
                    animate={true}
                    style={{ width: 200, height: 200 }}
                />
            </Grid>
            <Grid item>
                <Typography align="center" variant="h6" style={{ width: "90vw", maxWidth: "500px", marginTop: "1vh" }}>You received <b>{props.marksAwarded} out of a possible {props.marksAvailable}</b> marks and took <b>{Math.ceil(props.timeTakenSeconds / 60)} minutes </b> to answer all questions</Typography>
            </Grid>
            <Grid item>
                <LinkContainer style={{ marginRight: "5vw", marginTop: "10vh", textDecoration: "none", color: "white" }} to={{
                    pathname: '/ExamReview',
                    examId: props.location.examId,
                    practice: false
                }}><Button size="large" color="primary" variant="contained">Review Questions <br /> <RateReviewIcon /></Button></LinkContainer>
                <LinkContainer style={{ marginLeft: "5vw", marginTop: "10vh", borderWidth: "1.5px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4.06612px 16.2645px", textDecoration: "none", color: "inherit" }} to='/'><Button color="primary" variant="outlined" size="large">Continue<br /> <SendIcon /></Button></LinkContainer>
            </Grid>
        </React.Fragment>
    )
}

export default connect(
    state => state.examResult,
    dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(withOrientationChange(ExamResult));
