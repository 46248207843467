import React from 'react';
import { connect } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { Container } from '@material-ui/core';

function Header(){
    return(
        <AppBar
    
   style={{height: '10vh', alignItems: 'center'}}>
       <Toolbar style={{display: 'block'}}>
        <img style={{height: '10vh', width: 'auto'}} src={localStorage.getItem("headerImagePath")}/>
      </Toolbar>
       </AppBar>
    )
}

export default Header;