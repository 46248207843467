import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, BrowserRouter as Router } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Header from './Header';
import Footer from './Footer';
import RingLoader from "react-spinners/RingLoader";

export default function Loading(){
    return(
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}>
               <Header>
               </Header>
                <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{position: "absolute", top: "50%", margin: '0px', transform: "translateY(-50%)"}}
                  >
                      <Grid item>
                        <RingLoader size={150} color={localStorage.getItem("secondaryColour") != null ? localStorage.getItem("secondaryColour") : "#AA5088"} loading={true} />
                      </Grid>
                </Grid>
        <Footer></Footer>
        </div>
    )
}