import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import GroupIcon from '@material-ui/icons/Group';
import SendIcon from '@material-ui/icons/Send';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TimerIcon from '@material-ui/icons/Timer'
import Header from './shared/Header';
import Footer from './shared/Footer';
import Typography from '@material-ui/core/Typography';
import { LinkContainer} from 'react-router-bootstrap';
import {Link} from 'react-router-dom';
import uuid from "uuid";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) =>({
    typographyContent: {
        width: "90vw",
        maxWidth: "900px"
    }
  }));

function BenchmarkExamSubmission(props){

    const classes = useStyles();

        return(
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
              }}>
            <Header></Header>
            <Grid container
            direction="column"
            justify="flex-start"
            alignItems="center"
                           
                            style={{marginTop: "12vh", marginBottom: "2vh"}}
            >
                <Grid item>
                <img style={{width: "80vw", height: "auto", maxWidth: "600px", marginBottom: "5vh"}} src="https://nebulaexamsenterprise.blob.core.windows.net/media/graphics/satellite%20[Converted].svg?sv=2019-12-12&st=2021-01-07T22%3A37%3A30Z&se=2023-01-08T22%3A37%3A00Z&sr=b&sp=r&sig=pAWQJ1Tm6lkOLjVB2MPWdxQgHMIlwSbFJONGZKKiq1A%3D"></img>
                
                </Grid>
                <Grid item>
                <Typography variant="h3" align="center" className={classes.typographyContent}><b>You have completed the Benchmark Assessment</b> 🙌</Typography>
                <br />
                </Grid>
                <Grid item>
                <Typography variant="body1" align="center" className={classes.typographyContent}>You can view your updated scores by returning to the home page</Typography>
                </Grid>
                <Grid item>
                <Link
                  to={{
                    pathname: '/',
                    preferences: props.location.preferences,
                    examId: props.location.examId,
                    isPractice: false
                  }}

                  style={{textDecoration: "none", color: "inherit"}}
                ><Button style={{marginTop: "5vh"}} color="secondary" variant="contained">Back To Home</Button></Link>
                </Grid>
          </Grid>
          <Footer></Footer>
       </div>
        )
}
   

export default BenchmarkExamSubmission;
