import React, { Component, useEffect } from 'react';
import { MathComponent } from 'mathjax-react'
import { Typography } from '@material-ui/core';
import Latex from '../Misc/Latex';


export default function MathJaxContent(props) {
    return (
        <Latex>{props.text}</Latex>
    )
}