import React, { Component } from 'react';
import Typography  from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function List({listValues}){
    return(
        <Box
        display="flex"
        flexWrap="wrap"
        bgcolor="background.paper"
        borderRadius={16}
        border={1}
        style={{margin: "10px" }}
      >
          {listValues.map((listValue, index) => {
              return(<Typography key={index} style={{padding: "10px"}} variant="h6"><b>{listValue}</b></Typography>)
          })}
      </Box>
    )
}