import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/ExamReview';
import Button from '@material-ui/core/Button';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SendIcon from '@material-ui/icons/Send';
import { Link, BrowserRouter as Router } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Header from './shared/Header';
import Footer from './shared/Footer';
import Loading from './shared/Loading';
import QuestionAnswerForm from './shared/QuestionAnswerForm';
import Container from '@material-ui/core/Container';
import * as commonService from '../shared/commonService';
import { fetchReviewQuestions } from '../shared/apiService';
import { withOrientationChange } from 'react-device-detect';
import ProgressBar from './shared/Misc/ProgressBar';
import styled from 'styled-components';
import FormLabel from '@material-ui/core/FormLabel';
import { LinkContainer } from 'react-router-bootstrap';

class ExamReview extends React.Component {

  componentWillMount() {
    mixpanel.track("ExamReview");
    this.props.fetchReview(this.props.location.examId);
  }

  render() {
      if (this.props.loading == false) {

        return (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}>
            <Header></Header>
            <Grid container
              direction="column"
              alignItems="center"
              style={{ marginTop: "20vh", marginBottom: "2vh" }}
            >
              <QuestionAnswerFormContainer {...this.props} />
            </Grid>
            <Footer></Footer>
          </div>
        )
      } else {
        return (<Loading />)
      }
  }
}

function QuestionAnswerFormContainer(props) {
  return (
    <React.Fragment>
      <Grid item>
        <BackLink {...props} />
        <Grid item>
          <ProgressBar value={(((props.currentQuestionIndex+1) / (props.questions.length)) * 100)} />
        </Grid>
        <QuestionAnswerForm isReview={props.isReview} currentQuestion={props.currentQuestion} {...props}></QuestionAnswerForm>
      </Grid>
      <Grid item style={{ marginTop: "3vh" }}>
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={() => props.previousReviewQuestion()} disabled={props.currentQuestionIndex == 0}>Previous</Button>
        <Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={() => props.nextReviewQuestion()} disabled={props.currentQuestionIndex == props.questions.length - 1}>Next</Button>
      </Grid>
    </React.Fragment>
  )
}

function BackLink(props) {

  const HoverText = styled.p`
  font-size:14px;
  :hover {
    font-weight: bold;
    cursor: pointer;
  }
`
  if (props.location != null) {
    if (props.location.redirectedFromStatsPage == true) {
      return (
        <Grid item>
          <LinkContainer to={{
            pathname: '/UserStats',
            subjectId: props.currentQuestion.subject,
            deckId: props.currentQuestion.deck
          }}><FormLabel size="large"><HoverText>⇦ Back To Stats</HoverText></FormLabel></LinkContainer>
        </Grid>
      )
    }
  }
  return (
    <Grid item>
      <LinkContainer to={{
        pathname: '/ExamResult'
      }}><FormLabel size="large"><HoverText>⇦ Back to Results</HoverText></FormLabel></LinkContainer>
    </Grid>
  )



}

export default connect(
  state => state.examReview,
  dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(withOrientationChange(ExamReview));