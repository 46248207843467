import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import QuestionContent from './QuestionContent/index';
import InputType from './InputType/index';
import ReasoningContent from './Reasoning/index';

const useStyles = makeStyles({
    root: {
      minWidth: '350px',
      maxWidth: '1200px',
      width: '90vw',
      padding: "10px"
    },
    submit: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    submitButton: {
    },
    media: {
        height: 'auto',
        maxWidth: '100%',
        minWidth: '300px',
      },
    reasoningContinue: {
        position:'absolute',
        bottom:'0',
        right:'0',
        marginBottom: 5,
        marginRight: 5,
        color: 'inherit'
    },
    reasoningHeader: {
        fontSize: ''
    },
    openResponseAnswerBox: {
    },
    
  });

export default function QuestionAnswerForm(props){

    const classes = useStyles();

    return(
            <React.Fragment>
                <Grid item>
                    <Card className={classes.root}>
                        <QuestionTypeComponent {...props}></QuestionTypeComponent>
                    </Card>
                </Grid>
            </React.Fragment>
    )
}

class QuestionTypeComponent extends React.Component{

    render(){
        if(this.props.currentQuestion.groupedQuestion){
            const GroupedComponent = QuestionDataHandler(GroupedQuestion, this.props)
            return <GroupedComponent />
        }else{
            return <React.Fragment />
        }
    }
}

class GroupedQuestion extends React.Component{

    render(){

        return(
            <React.Fragment>
                {this.props.currentQuestion.questions.map((currentQuestion) => {
                   return ( 
                   <Grid container direction="column" alignItems="center" key={currentQuestion.id}>
                       <QuestionContent currentQuestion={currentQuestion} /> 
                        <InputType currentQuestion={currentQuestion} isReview={this.props.isReview} />
                   </Grid>     
                    )
                })}
                <ReasoningContent currentQuestion={this.props.currentQuestion} 
                        isReview={this.props.isReview} 
                        isPractice={this.props.location.isPractice}
                        nextQuestion={this.props.nextQuestion}
                        />
                 <SubmitGroupAnswers {...this.props} />
            </React.Fragment>
        )

    }
}

function QuestionDataHandler(QuestionComponent, props){
    return class extends React.Component{

        render(){
            return (
                    <QuestionComponent 
                    {...props}
                    ></QuestionComponent>
            );
        }
    }
}

function SubmitGroupAnswers(props){

    const classes = useStyles();

    return(
        <React.Fragment>
                    <CardActions className={classes.submit}>
                    {props.isReview ? null :
                        <Button disabled={props.submitDisabled} className={classes.submitButton} variant="contained" color="primary" onClick={(event) => props.submitGroupedAnswer(props.location.examId, props.location.isPractice)}>Submit answer</Button>
                    }
                </CardActions>
        </React.Fragment>
    )
}