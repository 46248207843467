import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableLineInput from './TableLineInput';
import MathJaxContent from '../QuestionContent/MathJaxContent';
import { Line } from 'tryal-ui';
import * as answerService from '../../../shared/answerService';
import uuid from "uuid";
import { isReview } from '../../../shared/commonService';

class TableInput extends React.Component{

    constructor(props){
        super(props);

        this.state = {rows: this.props.rows, rowReview: false};
    }

    componentDidMount(){
        document.addEventListener('keyup', this.handleChange);

        if(this.props.isReview){

            var submittedInputValues = this.props.currentQuestion.userAnswer;
            var submitIndex = 0;
            var reviewRows = [];

            for(var row of this.props.rows){
                for(var [cellIndex, cell] of row.entries()){
                    if(cell.type != null){
                        cell.value = submittedInputValues[submitIndex]
                        submitIndex++;
                        row[cellIndex] = cell;
                    }
                }

                reviewRows.push(row);
            }

            this.setState({rows: reviewRows, rowReview: true});
        }
    }

    handleChange = () => {
        var tableInputElement = document.getElementById("tableInputContainer");
        var lineInputs = tableInputElement.getElementsByTagName("input");
        var submittedValues = [];
        
        for(var lineInput of lineInputs){
            submittedValues.push(lineInput.value);
        }
        
        answerService.setAnswerValue(this.props.currentQuestion.id, submittedValues);
    }

    updateReviewIndex = () => {
        this.setState({
            reviewIndex: this.state.reviewIndex + 1
        })
    }

    render(){

        return(
            <TableContainer id="tableInputContainer">
                <Table>
                    <TableBody>
                        {this.state.rows.map((row, index) => (
                            <TableRow key={index}>
                                {
                                    row.map((cell) => (
                                       <SwitchTableInput 
                                       cell={cell} 
                                       isReview={this.state.rowReview}/>
                                    ))
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}

function SwitchTableInput({cell,isReview})
{
    if(typeof(cell) == "string"){
        return(<TableCell><MathJaxContent text={cell}/></TableCell>)
    }else{
        return( <TableCell><TableLineInput cell={cell} lineId={uuid.v4()} isReview={isReview}/></TableCell>)
    }
}

export default TableInput;