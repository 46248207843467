import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import Text from './Text';
import MultipleAnswers from './MultipleAnswers';
import MultipleChoice from './MultipleChoice';
import TryalWorkings from './TryalWorkings';
import TableInput from './TableInput';
import TryalGraph from './TryalGraph';
import uuid from "uuid";

export default function InputType({ currentQuestion, isReview }) {

    return (
            <InputTypeItem currentQuestion={currentQuestion} isReview={isReview} />
        )

}

function InputTypeItem({ currentQuestion, isReview }) {
    switch (currentQuestion.inputType) {
        case "text":
            return <Text currentQuestion={currentQuestion} isReview={isReview} />
        case "multipleChoice":
            return <MultipleChoice currentQuestion={currentQuestion} isReview={isReview} />
        case "multipleAnswers":
            return <MultipleAnswers currentQuestion={currentQuestion} isReview={isReview} />
        case "tryalWorkings":
            return <TryalWorkings currentQuestion={currentQuestion} workingsId={uuid.v4()} isReview={isReview} />
        case "table":
            return <TableInput currentQuestion={currentQuestion} rows={currentQuestion.rows} isReview={isReview} />
        case "tryalGraph":
            return <Grid item><TryalGraph currentQuestion={currentQuestion} graphId={uuid.v4()} isReview={isReview} /></Grid>
        default:
            return <React.Fragment/>
    }
}