import React, { Component, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import '../layout.css';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import Rating from '@material-ui/lab/Rating';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  drawerList: {
    width: "330px"
  },
  ratingReviewModal: {
    position: 'absolute',
    width: "40vw",
    minWidth: "330px",
    [theme.breakpoints.down('sm')]: {
      height: "410px"
    },
    [theme.breakpoints.up('md')]: {
      height: "390px"
    },
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  ratingReviewSuccess: {
    position: 'absolute',
    width: "300px",
    height: "300px",
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  ratingReviewTextbox: {
    position: "absolute",
    display: "block",
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down('sm')]: {
      top: "130px",
    },
    [theme.breakpoints.up('md')]: {
      top: "110px"
    },
    width: "90%"
  },
  addedDeckListItem: {
    paddingLeft: "20px",
    position: "relative",
    height: "70px",
    borderColor: theme.palette.secondary.main,
    borderRadius: "4px"
  }
}));

function TemporaryDrawer(props) {

  const classes = useStyles();

  function handleClick(e) {
    e.preventDefault();
  }

  const list = () => (
    <div
      role="presentation"
      // onClick={props.toggleDrawer(false)}
      className={classes.drawerList}
    >
      <ListItem>
        <ListItemText>
          <Typography variant="h2">Hi, {props.name}</Typography>
        </ListItemText>
        <Button
          variant="outlined"
          startIcon={<MeetingRoomIcon />}
          style={{ color: "grey" }}
          onClick={props.onSignOut}
        >
          Logout
          </Button>
      </ListItem>
      <Divider />
      <List onClick={handleClick} style={{ padding: 0 }}>
        <ListItem>
          <ListItemText>
            <Typography variant="h4">Subscribed decks</Typography>
          </ListItemText>
        </ListItem>

        {
          props.subscribedDecks.length > 0 ?
            props.subscribedDecks.map((subscribedDeck) => {
              return (
                <AddedDeckItem
                  key={subscribedDeck.id}
                  userId={props.userId}
                  deckId={subscribedDeck.id}
                  name={subscribedDeck.name}
                  rating={subscribedDeck.averageRating}
                  logoPath={subscribedDeck.logoPath}
                  submitReview={props.submitReview}
                  userName={props.fullName}
                  submittedReviewSuccessfully={props.submittedReviewSuccessfully}
                  switchDeck={props.switchDeck}
                  currentlySelected={(props.selectedDeckId == subscribedDeck.id)} />
              )
            }) : <ListItem className={classes.nested}><Typography variant="caption">You haven't added any decks yet...</Typography></ListItem>
        }
      </List>
      <Divider />

      <List>
        <ListItem>
          <ListItemText>
            <Typography variant="h4" fontWeight="bold">Available decks</Typography>
          </ListItemText>
        </ListItem>
        {
          props.availableDecks.length > 0 ?
            props.availableDecks.map((availableDeck) => {
              return (
                <AvailableDeckItem
                  key={availableDeck.id}
                  name={availableDeck.name}
                  logoPath={availableDeck.logoPath}
                  numberOfQuestions={availableDeck.numberOfQuestions}
                  addDeckToUserDeck={props.addDeckToUserDeck}
                  userId={props.userId}
                  deckId={availableDeck.id}
                  rating={availableDeck.averageRating}
                  providerProps={JSON.parse(availableDeck.providerProps)}
                />
              )
            }) : <ListItem className={classes.nested}><Typography variant="caption">More to come soon...</Typography></ListItem>
        }
      </List>
      <Divider />
    </div>
  );

  return (
    <div>
      <Drawer open={props.drawerOpen} onClose={props.toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
}

function RateReviewModal(props) {

  if (props.submittedReviewSuccessfully) {
    return (<RatingSuccess
      modalOpen={props.modalOpen}
      setModalOpen={props.setModalOpen} />)
  } else {
    return (<RateReviewForm
      submitReview={props.submitReview}
      modalOpen={props.modalOpen}
      setModalOpen={props.setModalOpen}
      rating={props.rating}
      setRating={props.setRating}
      name={props.name}
      userName={props.userName}
      userId={props.userId}
      deckId={props.deckId} />)
  }

}

function RateReviewForm(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [review, setReview] = React.useState("");

  const handleClose = () => {
    props.setModalOpen(false);
  };

  function handleRatingClick(rating) {
    props.setRating(parseFloat(rating));
  }

  function handleReviewChange(review) {
    setReview(review);
  }

  return (
    <Modal
      open={props.modalOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.ratingReviewModal}>
        <Typography variant="h3" align="center"><b>{props.name}</b></Typography>
        <Rating name="size-large" style={{ position: "absolute", display: "block", left: "50%", transform: "translateX(-50%)", fontSize: "3rem" }} value={props.rating} defaultValue={0} precision={0.5} onClick={(event) => { handleRatingClick(event._targetInst.return.key) }} />
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={10}
          placeholder="Leave a review... Max 300 characters (optional)"
          variant="outlined"
          fullWidth="true"
          className={classes.ratingReviewTextbox}
          value={review}
          onChange={(event) => handleReviewChange(event.target.value)}
          onInput={(e) => {
            e.target.value = e.target.value.slice(0, 300)
          }}
        />
        <Button variant="contained" color="primary" onClick={(event) => props.submitReview(props.userId, props.userName, props.deckId, props.rating, review)} style={{ width: "90%", minWidth: "250x", position: "absolute", display: "block", left: "50%", transform: "translateX(-50%)", bottom: "15px" }}>Submit Review</Button>
      </div>
    </Modal>
  )
}

function RatingSuccess(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    props.setModalOpen(false);
  };

  return (
    <Modal
      open={props.modalOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.ratingReviewSuccess}>
        <Typography variant="h3" align="center" style={{ margin: "5px 10px 5px 10px" }}><b>Thankyou for submitting a review!</b></Typography>
        <Typography align="center" style={{ fontSize: "5rem" }}>👏</Typography>
      </div>
    </Modal>
  )
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    position: "relative"
  };
}

function AddedDeckItem(props) {

  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [rating, setRating] = React.useState(0);
  const [hoverBg, setHoverBg] = React.useState(false);

  function handleRatingClick(rating) {
    setRating(parseFloat(rating));
    setModalOpen(true);
  }

  function handleSwitchDeck(event, deckId) {

    if (event.target.tagName.toLowerCase() == "li" || event.target.tagName.toLowerCase() == "p" || event.target.tagName.toLowerCase() == "img") {
      props.switchDeck(deckId);
    }

  }

  return (
    <ListItem className={classes.addedDeckListItem} onClick={(event) => handleSwitchDeck(event, props.deckId)} onMouseEnter={() => setHoverBg(true)} onMouseLeave={() => setHoverBg(false)} alignItems="flex-start" style={{ backgroundColor: hoverBg == true ? "#F0F0F0" : "", borderStyle: props.currentlySelected == true ? "solid" : "none" }}>
      <ListItemAvatar >
        <Avatar src={props.logoPath} />
      </ListItemAvatar>
      <ListItemText
        style={{ top: "-20px" }}
        primary={<Typography style={{ width: "250px" }} variant="body1">{props.name}</Typography>}
      />
      <Rating style={{ top: "30px", right: "145px" }} size="large" name="half-rating" value={props.rating} defaultValue={0} precision={0.5} onClick={(event) => { handleRatingClick(event._targetInst.return.key) }} />
      <RateReviewModal
        submitReview={props.submitReview}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        rating={rating}
        setRating={setRating}
        name={props.name}
        userId={props.userId}
        userName={props.userName}
        deckId={props.deckId}
        submittedReviewSuccessfully={props.submittedReviewSuccessfully} />
    </ListItem>
  )
}

function AvailableDeckItem(props) {

  const classes = useStyles();

  return (
    <ListItem alignItems="flex-start" style={{ paddingLeft: "20px", position: "relative", height: "120px" }} className={classes.nested}>
      <ListItemText
        style={{ position: "absolute", left: "75px" }}
        primary={<Typography style={{ width: "250px" }} variant="body1">{props.name}</Typography>}
      />
      <ListItemText
        style={{ position: "absolute", left: "75px", top: "30px" }}
        primary={<Typography style={{ width: "150px" }} variant="caption">Provided by <a href={props.providerProps.link} target="_blank" rel="noopener noreferrer">{props.providerProps.label}</a></Typography>}
      />
      <ListItemAvatar style={{ position: "absolute", top: "35px" }}>
        <Avatar src={props.logoPath} />
      </ListItemAvatar>
      <Rating style={{ position: "absolute", left: "73px", top: "52px" }} value={props.rating} readOnly size="large" name="half-rating" defaultValue={0} precision={0.5} />
      <ListItemText
        style={{ position: "absolute", left: "77px", top: "68px" }}
        primary={<Typography variant="caption" >
          {props.numberOfQuestions} Questions</Typography>}
      />
      <ListItemText style={{ position: "absolute", left: "77px", top: "85px" }}
        primary={<Typography variant="caption"><Link to={{
          pathname: '/MoreDetailsDeck',
          deckId: props.deckId
        }}><a>More details...</a></Link></Typography>}></ListItemText>
      <Button onClick={(event) => props.addDeckToUserDeck(props.userId, props.deckId)} variant="contained" size="small" style={{ left: "200px", top: "75px", backgroundColor: "green", color: "white" }}>+ Free</Button>
    </ListItem>
  )
}

export default TemporaryDrawer;