import React, { Component, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Deck';
import { Link, BrowserRouter as Router } from "react-router-dom";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TimerIcon from '@material-ui/icons/Timer';
import Grid from "@material-ui/core/Grid";
import Header from './shared/Header';
import Footer from './shared/Footer';
import Loading from './shared/Loading';
import '../layout.css';
import { heartbeat } from '../shared/apiService';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import uuid from "uuid";
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import styled, { keyframes } from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BenchmarkPopup from './BenchmarkPopup';
import TemporaryDrawer from './TemporaryDrawer';

const localStorage = window.localStorage;

const useStyles = makeStyles((theme) => ({
  topics: {
    width: "300px",
    height: "310px",
    margin: 20,
    position: "relative",
    boxShadow: "3px 3px 5px 6px #ccc"

  },
  tabs: {
    flexGrow: 1,
    color: "background",
  },
  title: {
    position: "absolute",
    top: "155px",
    right: "0px",
    textAlign: "center"
  },
  submit: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  viewSubTopicButton: {
    position: "absolute",
    left: "140px",
    top: "265px",
    borderWidth: "1.5px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4.06612px 16.2645px"
  },
  panel: {
    color: "background"
  },
  progressBar: {
    position: "absolute",
    top: "210px",
    width: "90%"
  },
  cover: {
    height: 120,
    width: 120,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  startTestButton: {
    position: "fixed",
    bottom: "0px",
    right: "0px"
  },
  menuButton: {
    margin: "0vh 2vw 0vh 2vw"
  },
  drawerList: {
    width: "330px"
  },
  inline: {
    display: 'inline',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  practiceButton: {
    position: "absolute",
    left: "30px",
    top: "265px",
  },
  subTopicPracticeButton: {
    position: "absolute",
    left: "110px",
    top: "265px",
    textDecoration: "none"
  },
  appBarIcons: {
    width: "25px",
    height: "auto"
  },
  startExamButton: {
    position: "fixed",
    bottom: "2vh",
    right: "5vw",
    height: "80px",
    width: "80px",
    border: "solid",
    borderWidth: "2px 2px 4px",
    borderColor: "",
    background: "white"
  },

  startExamButtonFlashing: {
    position: "fixed",
    bottom: "2vh",
    right: "5vw",
    height: "80px",
    width: "80px",
    border: "solid",
    borderColor: "",
    animationName: "blinking",
    animationDuration: "3s",
    animationIterationCount: "infinite",
    borderWidth: "2px 2px 4px",
    background: "white"
  },
}));

class Deck extends React.Component {

  constructor(props) {
    super(props);

    this.state = { drawerOpen: false };
  }

  componentWillMount() {
    this.props.fetchUserDeck(this.props.accountInfo.accountIdentifier, this.props.accountInfo.name, this.props.accountInfo.idToken.emails[0]);
    this.props.fetchDecks(this.props.accountInfo.accountIdentifier);

    var userId = localStorage.getItem("userId");

    if (userId != this.props.accountInfo.accountIdentifier) {
      localStorage.setItem("userId", this.props.accountInfo.accountIdentifier);
      window.location.reload();
    }

    heartbeat().then(function () { });
  }

  componentDidUpdate(prevProps) {

    if (this.props.courseId != prevProps.courseId) {
      mixpanel.track("OpenedDeck", { "deckId": this.props.courseId })
    }
  }

  // componentWillUnmount() {
  //   this.props.clear();
  // }

  toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ ...this.state, drawerOpen: open });
  };

  getFirstName = () => {
    return this.props.accountInfo.name.split(" ")[0]
  }

  render() {

    if (this.props.loading == false) {
      if (this.props.courseId == null) {
        return (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}>
            <Header>
            </Header>
            <Grid container
              direction="column"
              justify="space-between"
              alignItems="center"
              style={{ position: "absolute", top: "50%", margin: '0px', transform: "translateY(-50%)" }}
            >
              <Grid item>
                <img style={{ width: "80vw", height: "auto", maxWidth: "600px" }} src="https://nebulaexamsenterprise.blob.core.windows.net/media/graphics/nodecks-new-bg.svg?sv=2019-12-12&st=2021-01-26T21%3A17%3A27Z&se=2023-01-27T21%3A17%3A00Z&sr=b&sp=r&sig=Oc5qvyuk23Z0XH61v349TvRrSuYrn8Z6oGMl9z2feQo%3D"></img>
              </Grid>
              <Grid item>
                <Typography variant="h3" align="center" ><b>No decks here</b></Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" align="center">Add a question deck using the button below to get started</Typography>
              </Grid>
              <Grid item>
                <Button size="large" style={{ marginTop: "2vh" }} variant="contained" color="secondary" onClick={this.toggleDrawer(true)}>+ Add Deck</Button>
              </Grid>
            </Grid>
            <TemporaryDrawer
              toggleDrawer={this.toggleDrawer}
              drawerOpen={this.state.drawerOpen}
              name={this.getFirstName()}
              onSignOut={this.props.onSignOut}
              availableDecks={this.props.availableDecks}
              subscribedDecks={this.props.subscribedDecks}
              addDeckToUserDeck={this.props.addDeckToUserDeck}
              userId={this.props.accountInfo.accountIdentifier}
              fullName={this.props.accountInfo.name}
              submitReview={this.props.submitReview}
              submittedReviewSuccessfully={this.props.submittedReviewSuccessfully}
              switchDeck={this.props.switchDeck}
              selectedDeckId={this.props.courseId}
            >
            </TemporaryDrawer>
            <Footer>
            </Footer>
          </div>
        )
      } else {
        return (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}>
            <Grid container
              direction="column"
              justify="center"
              color="background"
            >
              <SubjectTabs {...this.props} toggleDrawer={this.toggleDrawer} deckInfo={this.props.deckInfo}></SubjectTabs>
            </Grid>
            <TemporaryDrawer
              toggleDrawer={this.toggleDrawer}
              drawerOpen={this.state.drawerOpen}
              name={this.getFirstName()}
              onSignOut={this.props.onSignOut}
              availableDecks={this.props.availableDecks}
              subscribedDecks={this.props.subscribedDecks}
              userId={this.props.accountInfo.accountIdentifier}
              userEmail={this.props.accountInfo.email}
              fullName={this.props.accountInfo.name}
              addDeckToUserDeck={this.props.addDeckToUserDeck}
              submitReview={this.props.submitReview}
              submittedReviewSuccessfully={this.props.submittedReviewSuccessfully}
              switchDeck={this.props.switchDeck}
              selectedDeckId={this.props.courseId}
            ></TemporaryDrawer>
            <Footer></Footer>
            {/* <BenchmarkPopup benchmarkPopupClose={this.props.benchmarkPopupClose} benchmarkPopupShow={this.props.benchmarkPopupShow} name={this.props.accountInfo.name} /> */}
          </div>
        )
      }
    } else {
      return (<Loading />)
    }
  }
}

function SubjectPanel(props) {
  const { children, value, index, id, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid item>
          {children}
        </Grid>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function SubjectTabs(props) {

  const lastTabIndex = localStorage.getItem("lastTabIndex") != null ? parseInt(localStorage.getItem("lastTabIndex")) : 0;

  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(lastTabIndex);
  const [subjectId, setSubjectId] = React.useState(props.subjects[lastTabIndex].id);
  const [subjectLabel, setSubjectLabel] = React.useState(props.subjects[lastTabIndex].label);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);

    setSubjectId(props.subjects[newValue].id);
    setSubjectLabel(props.subjects[newValue].label);

    props.viewSubTopic("");
    localStorage.setItem("lastTabIndex", newValue);
  };

  const HoverText = styled.p`
      font-size:14px;
      :hover {
        font-weight: bold;
        cursor: pointer;
      }
    `

  return (
    <div className={classes.tabs}>
      { props.currentTopicId != "" ? <FormLabel component="legend" size="large" style={{ marginBottom: "0px", marginTop: "10px" }} onClick={(event) => props.viewSubTopic("")}><HoverText>⇦ Back to Topics</HoverText></FormLabel> : ""}
      <AppBar position="static" style={{ alignItems: 'center' }}>
        <Toolbar style={{ width: "100%", position: "relative" }}>
          <IconButton start="edge" color="inherit" style={{ color: 'white', position: "absolute", left: "20px" }} aria-label="menu" onClick={props.toggleDrawer(true)}>
            <MenuIcon className={classes.appBarIcons} />
          </IconButton>
          <Tabs value={tabIndex} onChange={handleTabChange} style={{ maxWidth: '50vw', marginLeft: "auto", marginRight: "auto" }} aria-label="simple tabs example" variant="scrollable" scrollButtons="on">
            {
              props.subjects.map((subject, index) => {
                return (
                  <Tab key={subject.id} label={subject.label} {...a11yProps(index)} />
                )
              })
            }
          </Tabs>
          <Link to={{ pathname: "/UserStats", subjectId: subjectId, deckId: props.courseId }} style={{ color: 'white', position: "absolute", right: "20px" }}>
            <IconButton end="edge" color="inherit" aria-label="menu">
              <EqualizerIcon className={classes.appBarIcons} />
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>
      {
        props.subjects.map((subject, index) => {
          return (
            <SubjectPanel key={"panel" + subject.id} value={tabIndex} index={index}>
              <TopicSet courseId={props.courseId} topics={subject.topics} currentTopicId={props.currentTopicId} viewSubTopic={props.viewSubTopic} subjectId={subject.id}></TopicSet>
              <StartExamButton courseId={props.courseId} subject={subject} deckInfo={props.deckInfo} />
            </SubjectPanel>
          )
        })
      }
    </div>
  );
}

function TopicSet(props) {

  return (
    <Grid container justify="center">
      { props.topics.map((topic) => {
        return (
          <React.Fragment key={"frag-" + topic.id}>
            {props.currentTopicId == "" ? <TopicCard courseId={props.courseId} topic={topic} isSubTopic={false} viewSubTopic={props.viewSubTopic} subjectId={props.subjectId}></TopicCard> : null}
            <SubTopicSet courseId={props.courseId} subTopics={topic.subTopics} topicId={topic.id} currentTopicId={props.currentTopicId} subjectId={props.subjectId}></SubTopicSet>
          </React.Fragment>
        )
      })
      }
    </Grid>
  )
}

function SubTopicSet(props) {

  if (props.currentTopicId == props.topicId) {
    return (
      <React.Fragment>
        {props.subTopics.map((subTopic) => {
          return (
            <TopicCard key={"subtopiccard-" + subTopic.id} courseId={props.courseId} parentTopicId={props.topicId} topic={subTopic} isSubTopic={true} active={subTopic.active} subjectId={props.subjectId}></TopicCard>
          )
        })}
      </React.Fragment>
    )
  } else {
    return null;
  }
}

function calculateFontSize(topicLabel) {
  if (topicLabel.length <= 12) {
    return "3.25rem";
  } else if (topicLabel.length <= 18) {
    return "2.75rem";
  } else if (topicLabel.length <= 24) {
    return "2.25rem";
  } else {
    return "2rem";
  }
}

function TopicCard(props) {

  const classes = useStyles();

  if ((props.isSubTopic == true && props.active == true) || props.isSubTopic == false) {
    return (
      <React.Fragment>
        <Card className={classes.topics} color="tertiary.main">
          <div className={classes.details}>
            <CardContent >
              <Container style={{ width: "300px", height: "150px", position: "absolute", left: "0px" }}>
                <img
                  className={classes.cover}
                  src={props.topic.imagePath}
                />
              </Container>

              <Container className={classes.title}>
                <Typography variant="h3" align="center" style={{ fontSize: calculateFontSize(props.topic.label) }}>{props.topic.label}</Typography>
              </Container>

              <LinearProgressWithLabel style={{}} score={props.topic.score}></LinearProgressWithLabel>

              {props.isSubTopic == true ?
                <Link
                  className={classes.subTopicPracticeButton}

                  to={{
                    pathname: '/Exam',
                    preferences: { course: props.courseId, topicId: props.parentTopicId, subTopicId: props.topic.id, maxNumberOfQuestions: 5, examType: "subTopic", subjectId: props.subjectId },
                    examId: uuid.v4(),
                    isPractice: true
                  }}

                  style={{
                    textDecoration: "none"
                  }}
                >
                  <Button variant="contained" className={classes.topicButton} style={{ fontSize: "11px" }} color="secondary">Practice</Button>
                </Link> :
                <Link
                  className={classes.practiceButton}

                  to={{
                    pathname: '/Exam',
                    preferences: { course: props.courseId, topicId: props.topic.id, maxNumberOfQuestions: 20, examType: "topic", subjectId: props.subjectId },
                    examId: uuid.v4(),
                    isPractice: true
                  }}

                  style={{ textDecoration: "none" }}
                >
                  <Button variant="contained" color="secondary" style={{ fontSize: "11px" }}>Practice</Button>
                </Link>
              }

              {props.isSubTopic == false ? <Button variant="outlined" style={{ fontSize: "11px" }} className={classes.viewSubTopicButton} color="secondary" onClick={(event) => props.viewSubTopic(props.topic.id)}>View sub-topics</Button> : null}


            </CardContent>
          </div>

        </Card>
      </React.Fragment>
    )
  }else{
    return <React.Fragment/>
  }
}

function LinearProgressWithLabel(props) {

  const classes = useStyles();

  return (
    <Box className={classes.progressBar}>
      <Typography variant="body1">Score: {props.score}</Typography>
      <LinearProgress variant="determinate" color="primary" value={props.score} />
    </Box>
  )
}

function StartExamButton(props) {

  const [subTopicLength, setSubTopicLength] = React.useState(0);

  useEffect(() => {

    var count = 0;

    for (var topic of props.subject.topics) {
      for (var subTopic of topic.subTopics) {
        count++;
      }
    }

    setSubTopicLength(count);
  })

  if (props.subject.submittedBenchmarkSubTopics != null) {

    if (props.subject.submittedBenchmarkSubTopics.length < subTopicLength) {
      return <StartExamMenuOpener deckInfo={props.deckInfo} benchmarkLabel={"Continue benchmark assessment"} courseId={props.courseId} subjectId={props.subject.id} />
    } else {
      return (
        <Link
          to={{
            pathname: '/PreExam',
            preferences: { course: props.courseId, examType: "exam", subjectId: props.subject.id, maxNumberOfQuestions: 5 },
            examId: uuid.v4(),
            isPractice: false
          }}
        >
          <StartExamButtonIcon />
        </Link>
      )
    }

  } else {
    return <StartExamMenuOpener deckInfo={props.deckInfo} benchmarkLabel={"Take benchmark assessment"} courseId={props.courseId} subjectId={props.subject.id} />
  }

}

function StartExamMenuOpener(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget.firstChild);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <React.Fragment>
      <div onClick={handleClick}>
        <StartExamButtonIcon />
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StartExamMenuItem
          pathName="/PreExam"
          label="Take exam"
          courseId={props.courseId}
          subjectId={props.subjectId}
          imagePath="https://nebulaexamsenterprise.blob.core.windows.net/media/icons/webappicons/start-exam-icon-a.svg?sv=2019-12-12&st=2021-01-04T19%3A51%3A06Z&se=2023-01-05T19%3A51%3A00Z&sr=b&sp=r&sig=mTaCbb6aaJOs6MT2RTaX7uusQtxPQDWDFSnxItcGDe8%3D"
        />
        {JSON.parse(props.deckInfo.featureToggles).benchmarkExam == true ? (<StartExamMenuItem
          pathName="/PreBenchmarkExam"
          label={props.benchmarkLabel}
          courseId={props.courseId}
          subjectId={props.subjectId}
          imagePath="https://nebulaexamsenterprise.blob.core.windows.net/media/icons/webappicons/speedometer-a.svg?sv=2019-12-12&st=2021-01-04T19%3A45%3A15Z&se=2023-01-05T19%3A45%3A00Z&sr=b&sp=r&sig=vxhr%2Bst4klILOVN%2BKNdDDPq1gIVVwhINSk%2F%2BhekVZRM%3D"
        />) : (<React.Fragment />)}

      </Menu>
    </React.Fragment>

  )
}

function StartExamMenuItem(props) {
  return (
    <Link
      to={{
        pathname: props.pathName,
        preferences: { course: props.courseId, examType: "exam", subjectId: props.subjectId },
        examId: uuid.v4(),
        isPractice: false
      }}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <MenuItem>
        <ListItemIcon>
          <img width="30" height="30" src={props.imagePath} />
        </ListItemIcon>
        {props.label}
      </MenuItem>
    </Link>
  )
}

function StartExamButtonIcon(props) {

  const classes = useStyles();
  const [seenExamButton, setSeenExamButton] = React.useState("false");

  useEffect(() => {
    setSeenExamButton(localStorage.getItem("seenExamButton"));
  });

  const handleClick = () => {
    setSeenExamButton(true);
    localStorage.setItem("seenExamButton", true);
  }

  return (
    <IconButton
      className={seenExamButton == "true" ? classes.startExamButton : classes.startExamButtonFlashing}
      onClick={() => handleClick()}
      border={1}
      variant="outlined"
    >

      <img width="55px" height="55px" src="https://nebulaexamsenterprise.blob.core.windows.net/media/icons/webappicons/pencil-rocket-a.svg?sv=2019-12-12&st=2021-01-04T19%3A38%3A32Z&se=2023-01-05T19%3A38%3A00Z&sr=b&sp=r&sig=fqn3KWiZCzS%2BOt2qCs4R2YW4WMt7jjOmRYNN5VVS6Vo%3D" />
    </IconButton>
  )
}

export default connect(
  state => state.deck,
  dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(Deck);