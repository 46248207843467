import Promise from 'promise'
import config from './configService';
import { msalApp, GRAPH_REQUESTS} from '../authUtils';

const localBaseUrl = "http://localhost:7071/api/";
const devExamBaseUrl = "https://nebula-apim.azure-api.net/nebula-exam-api-dev/";
const devUserBaseUrl = "https://nebula-apim.azure-api.net/nebula-user-api-dev/";


const examBaseUrl = config.examBaseUrl;
const apiSubscriptionKey = config.apiSubscriptionKey;
const userUrl = config.userBaseUrl;
const publisherUrl = config.publisherBaseUrl;

const fetchExamQuestionsUrl = examBaseUrl + "FetchExamQuestions";
const fetchBenchmarkExamQuestionsUrl = examBaseUrl + "FetchBenchmarkQuestions";
const completeExamUrl = examBaseUrl + "CompleteExam";
const benchmarkSubmissionUrl = examBaseUrl + "BenchmarkSubmission";
const reviewUrl = examBaseUrl + "Review";
const fetchExamUrl = examBaseUrl + "FetchExam";
const awardMarksAnsweredQuestionUrl = examBaseUrl + "AwardMarksAnsweredQuestion";
const heartbeatUrl = examBaseUrl + "Heartbeat";
const fetchUserUrl = userUrl + "FetchUser";
const fetchUserDeckUrl = userUrl + "FetchUserDeck";
const fetchDecksUrl = userUrl + "FetchDecks";
const fetchUserExamsUrl = userUrl + "FetchUserExams";
const addDeckToUserDeckUrl = userUrl + "AddDeckToUserDeck";
const fetchUserStatsUrl = userUrl + "FetchUserStats";
const submitReviewUrl = publisherUrl + "AddRateReview";
const fetchRateReviewsUrl = publisherUrl + "FetchRateReviews";
const fetchDeckInfoUrl = publisherUrl + "FetchDeckInfo";

export const completeExam = (payload) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(completeExamUrl, payload, response).then(function(response){
                resolve(response);
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    });
}

export const benchmarkSubmission = (payload) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(benchmarkSubmissionUrl, payload, response).then(function(response){
                resolve(response);
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    });
}

export const fetchExamQuestions = (payload) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(fetchExamQuestionsUrl, payload, response).then(function(response){
                resolve(response.json());
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    });
}

export const fetchBenchmarkExamQuestions = (payload) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(fetchBenchmarkExamQuestionsUrl, payload, response).then(function(response){
                resolve(response.json());
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    });
}

export const fetchReviewQuestions = (data) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(reviewUrl, data, response).then(function(response){
                resolve(response.json());
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    });
}

export const fetchExam = (data) => {
    return new Promise(async function(resolve, reject){

        acquireNewBearerToken().then(function(response){
            postData(fetchExamUrl, data, response).then(function(response){
                resolve(response.json());
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    });
}

export const fetchUser = (data) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(fetchUserUrl, data, response).then(function(response){
                resolve(response.json());
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    })
}

export const fetchUserDeck = (data) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(fetchUserDeckUrl, data, response).then(function(response){
                resolve(response.json());
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    })
}

export const fetchDecks = (data) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(fetchDecksUrl, data, response).then(function(response){
                resolve(response.json());
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    })
}

export const fetchUserExams = (data) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(fetchUserExamsUrl, data, response).then(function(response){
                resolve(response.json());
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    })
}

export const addDeckToUserDeck = (data) => {
        return new Promise(async function(resolve, reject){
            acquireNewBearerToken().then(function(response){
                postData(addDeckToUserDeckUrl, data, response).then(function(response){
                    resolve(response.json());
                }).catch(function(response){
                    reject(response);
                });
            }).catch(function(response){
                reject(response);
            })
        })
}

export const fetchUserStats = (data) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(fetchUserStatsUrl, data, response).then(function(response){
                resolve(response.json());
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    })
}

export const awardMarksAnsweredQuestion = (data) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(awardMarksAnsweredQuestionUrl, data, response).then(function(response){
                resolve(response.json());
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    })
}

export const heartbeat = () => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(heartbeatUrl, {}, response).then(function(response){
                resolve();
            }).catch(function(response){
                reject();
            });
        }).catch(function(response){
            reject(response);
        })
    })
}

export const fetchRateReviews = (deckId) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(fetchRateReviewsUrl, deckId, response).then(function(response){
                resolve(response.json());
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    })
}

export const fetchDeckInfo = (deckId) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(fetchDeckInfoUrl, deckId, response).then(function(response){
                resolve(response.json());
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    })
}

export const submitReview = (data) => {
    return new Promise(async function(resolve, reject){
        acquireNewBearerToken().then(function(response){
            postData(submitReviewUrl, data, response).then(function(response){
                resolve(response);
            }).catch(function(response){
                reject(response);
            });
        }).catch(function(response){
            reject(response);
        })
    })
}



function acquireNewBearerToken(){
    return new Promise(function(resolve, reject){

        console.log("begin token acquisition");

        msalApp.acquireTokenSilent(GRAPH_REQUESTS.TOKEN_REQUEST).then(function(tokenResponse){
            resolve(tokenResponse.idToken.rawIdToken);
        }).catch(function(error){
            msalApp.acquireTokenRedirect(GRAPH_REQUESTS.TOKEN_REQUEST).then(function(tokenResponse){
                resolve(tokenResponse.idToken.rawIdToken);
            });
            console.log("error in obtainign access token");
        })
    })
}

//can we use axios for this instead?
const postData = (url = '', data = {}, accessToken) => {
    return new Promise(async function(resolve, reject){
        fetch(url, {
            method: 'POST', 
            cache: 'no-cache', 
            credentials: 'same-origin', 
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Ocp-Apim-Subscription-Key': apiSubscriptionKey,
                'Authorization': "Bearer " + accessToken
            },
            redirect: 'follow', 
            referrer: 'no-referrer', 
            body: JSON.stringify(data) 
        }).then((response) => {
            if(response.status == 200){
                resolve(response);
            }else{
                reject(response);
            }
        });
    });
}

