import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box'
import FormLabel from '@material-ui/core/FormLabel';
import { Typography, Checkbox } from '@material-ui/core';
import { QuestionContentItem } from '../QuestionContent/index';
import styled from 'styled-components';

const useStyles = makeStyles({
    reasoningBoxCorrect: {
        background: 'rgba(0, 255, 0, 0.35)',
        color: "green",
        marginLeft: 10,
        marginRight: 10,
        borderRadius: '25px',
        position: 'relative'
    },
    reasoningBoxIncorrect: {
        background: 'rgba(255, 0, 0, 0.35)',
        color: "red",
        marginLeft: 10,
        marginRight: 10,
        borderRadius: '25px',
        position: 'relative'
    },
    reasoningBoxNeutral: {
        background: 'rgba(255, 165, 0, 0.35)',
        color: "rgb(255, 165, 0)",
        marginLeft: 10,
        marginRight: 10,
        borderRadius: '25px',
        position: 'relative'
    },
    reasoningContinue: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        marginBottom: 5,
        marginRight: 5,
        color: 'inherit',
        textDecoration: "none"
    },

});

export default function ReasoningContent(props) {

    const classes = useStyles();
    var marksAwardedPercent = props.currentQuestion.marksAwarded / props.currentQuestion.marks;

    if (props.isReview) {
        if (marksAwardedPercent >= 0.8) {
            return (
                <Grid item>
                    <Box className={classes.reasoningBoxCorrect}>
                        <ReasoningContentInner nextQuestion={props.nextQuestion} currentQuestion={props.currentQuestion} headerText="Good job!" isPractice={props.isPractice}/>
                    </Box>
                </Grid>)
        } else if (marksAwardedPercent <= 0.2) {
            return (<Grid item>
                <Box className={classes.reasoningBoxIncorrect}>
                    <ReasoningContentInner nextQuestion={props.nextQuestion} currentQuestion={props.currentQuestion} headerText="Improvement needed!" isPractice={props.isPractice}/>
                </Box>
            </Grid>)
        } else {
            return (<Grid item>
                <Box className={classes.reasoningBoxNeutral}>
                    <ReasoningContentInner nextQuestion={props.nextQuestion} currentQuestion={props.currentQuestion} headerText="Almost there!" isPractice={props.isPractice}/>
                </Box>
            </Grid>)
        }
    } else {
        return (<React.Fragment />)
    }
}

function ReasoningContentInner(props) {

    const classes = useStyles();
    const HoverText = styled.p`
    font-size:1.75em;
    :hover {
      font-weight: bold;
      cursor: pointer;
    }
  `

    return (
        <CardContent>
            <Typography align="center" variant="h4">{props.headerText} </Typography>
            <Typography align="center" style={{fontSize: "1.5em"}}>You were awarded <b>{props.currentQuestion.marksAwarded} {props.currentQuestion.marksAwarded == 1 ? "mark" : "marks"}</b> out of a possible {props.currentQuestion.marks} </Typography>
            {props.currentQuestion.reasoningContent.map((reasoningContentItem) => {
                return (<QuestionContentItem questionContentItem={reasoningContentItem} />)
            })}
            <br />
            {props.isPractice ? <FormLabel onClick={(event) => props.nextQuestion(props.isPractice)} className={classes.reasoningContinue}><HoverText variant="h6">Continue ⇨</HoverText></FormLabel> : null}
        </CardContent>
    )
}