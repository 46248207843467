import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import { actionCreators } from '../store/PreExam';
import Card from '@material-ui/core/Card';
import GroupIcon from '@material-ui/icons/Group';
import SendIcon from '@material-ui/icons/Send';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TimerIcon from '@material-ui/icons/Timer'
import Header from './shared/Header';
import Footer from './shared/Footer';
import Typography from '@material-ui/core/Typography';
import { LinkContainer} from 'react-router-bootstrap';
import {Link} from 'react-router-dom';
import uuid from "uuid";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) =>({
    typographyContent: {
        width: "90vw",
        maxWidth: "800px"
    }
  }));

function PreBenchmarkExam(props){

    const classes = useStyles();

        if(props.location.examId == null){
            props.history.push({
                pathname: "/"
            })
        }else{
          mixpanel.track("PreBenchmarkExam");
        }

        return(
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
              }}>
            <Header></Header>
            <Grid container
            direction="column"
            justify="flex-start"
            alignItems="center"
                           
                            style={{marginTop: "10vh", marginBottom: "2vh"}}
            >
                <Grid item>
                <img style={{width: "80vw", height: "auto", maxWidth: "600px"}} src="https://nebulaexamsenterprise.blob.core.windows.net/media/graphics/satellite-new-bg.svg?sv=2019-12-12&st=2021-01-26T20%3A55%3A38Z&se=2023-01-27T20%3A55%3A00Z&sr=b&sp=r&sig=zZs%2FAHfUcrRTcZ9093TqexLpUUoJm9R8Dla0AP%2BMnMc%3D"></img>
                
                </Grid>
                <Grid item>
                <Typography variant="h3" align="center" className={classes.typographyContent}><b>Prepare to start the benchmark assessment</b></Typography>
                <br />
                </Grid>
                <Grid item>
                <Typography variant="body1" align="center" className={classes.typographyContent}>The aim of the benchmark assessment is to assess your strengths and weaknesses in the <b>shortest possible time.</b></Typography>
                <br/>
                </Grid>
                <Grid item>
                <Typography variant="body1" align="center" className={classes.typographyContent}><b>You can exit the assessment at any time.</b> Your progress will be saved for each sub-topic you complete.</Typography>
                <br/>
                </Grid>
                <Grid item>
                <Typography variant="body1" align="center" className={classes.typographyContent}>After exiting the assessment, you will be able to view your updated scores on the home page.</Typography>
                <br/>
                </Grid>
                <Grid item>
                    <Typography variant="body1" align="center" className={classes.typographyContent}>Do not worry if you can't finish the assessment in one session. <b>You can always continue an uncompleted benchmark assessment at a later date.</b> </Typography>
                    <br/>
                </Grid>
                <Grid item>
                <Typography variant="body1" align="center" className={classes.typographyContent}>This assessment is <b>not time limited</b></Typography>
                <br/>
                </Grid>
                <Grid item>
                <Typography variant="body1" align="center" className={classes.typographyContent}>Click on the button below to start the assessment. <b>Good luck!</b></Typography>
                </Grid>
                <Grid item>
                <Link
                  to={{
                    pathname: '/BenchmarkExam',
                    preferences: props.location.preferences,
                    examId: props.location.examId,
                    isPractice: false
                  }}

                  style={{textDecoration: "none"}}
                ><Button style={{marginTop: "5vh", marginRight: "2.5vw"}} color="secondary" variant="contained">Start Assessment</Button></Link>
                <Link
                  to={{
                    pathname: '/',
                  }}

                  style={{textDecoration: "none"}}
                ><Button style={{marginTop: "5vh", marginLeft: "2.5vw",  borderWidth: "1.5px", 
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4.06612px 16.2645px", backgroundColor: "white"}} color="secondary" variant="outlined">Back To Home</Button></Link>
                </Grid>
          </Grid>
          <Footer></Footer>
       </div>
        )
}
   

export default PreBenchmarkExam;
