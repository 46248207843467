import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { Text as TryalText } from 'tryal-ui';


// export default function Text({text}){
//     return (<Typography variant="h4" align="center" style={{padding: "5px"}}>
//         {text}
//     </Typography>)
// }

class Text extends React.Component{
    componentDidMount(){
        new TryalText({
            target: document.getElementById(this.props.id),
            props: {
                body: this.props.text
            }
        })
    }

    render(){
        return(
            <div id={this.props.id} style={{fontSize: "2em"}}></div>
        )
    }
}

export default Text;