import React, { Component, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import '../layout.css';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import Rating from '@material-ui/lab/Rating';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    benchmarkPopup: {
        position: 'absolute',
        width: "40vw",
        minWidth: "330px",
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    contentItem:{
        padding: "10px"
    }
}));

function getFirstName(name) {
    var nameSplit = name.split(' ');

    if (nameSplit.length > 0) {
        return nameSplit[0];
    } else {
        return name;
    }
}

function BenchmarkPopup(props) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    const handleClose = () => {
        props.benchmarkPopupClose();
    };

    return (
        <Modal
            open={props.benchmarkPopupShow}
            // open={true}
            onClose={handleClose}
        >
            <Grid container direction="column" style={modalStyle} className={classes.benchmarkPopup} >
                <Grid item className={classes.contentItem}>
                    <Typography variant="h3" align="center"><b>Hey {getFirstName(props.name)} 👋</b></Typography>
                </Grid>
                <Grid item className={classes.contentItem}>
                    <Typography variant="body1" align="center">To help Nebula assess your initial strengths and weaknesses, you might want to take a benchmark assessment.</Typography>
                </Grid>
                {/* <Typography variant="body1" align="center">This will help Nebula understand your strengths and weakness better and provide more relevant questions</Typography> */}
                <Grid item className={classes.contentItem}>
                    <Typography variant="body1" align="center">If you're interested, click on the rocket button and select 'Take benchmark assessment'. This will start a benchmark assessment for the currently selected subject</Typography>
                </Grid>
                <Grid item className={classes.contentItem} style={{paddingBottom: "0px"}}>
                    <Button variant="contained" color="primary" onClick={() => handleClose()} style={{ width: "90%", minWidth: "250x", display: "block", left: "50%", transform: "translateX(-50%)" }}>Ok, got it!</Button>
                </Grid>
            </Grid>
        </Modal>
    )
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        position: "relative"
    };
}

export default BenchmarkPopup;